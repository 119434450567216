import React, {useState, useRef, useEffect} from 'react';
import { Box, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion'; // For animation
import WhatsApp from '../../images/wsp.png';
import Phone from '../../images/phone.png';
import Email from '../../images/email.png';
import contact from '../../images/contact.jpg'
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
function NewsletterSubscriptionForm() {
  const [isOpen, setIsOpen] = useState(false); 
     const ref = useRef(null);
   
     const toggleIcons = () => {
       setIsOpen((prev) => !prev);
     };
   
     const handleClickOutside = (event) => {
       if (ref.current && !ref.current.contains(event.target)) {
         setIsOpen(false);
       }
     };
   
     useEffect(() => {
       if (isOpen) {
         document.addEventListener("mousedown", handleClickOutside);
       } else {
         document.removeEventListener("mousedown", handleClickOutside);
       }
   
       return () => {
         document.removeEventListener("mousedown", handleClickOutside);
       };
     }, [isOpen]);
     
  return (
    <Box sx={{ backgroundColor: "#0F1214" }}>
      {/* Contact Info Section with Animation */}
      <Grid container spacing={2} justifyContent="center" >
        {/* WhatsApp */}
        <Grid item xs={12} sm={4}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Box sx={{ p: 2, textAlign: 'center', boxShadow: 3, marginTop: {sm:'80px',xs:"70px"}, backgroundColor:"#fff" }}>
              <a href="https://wa.me/9583966999" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "black" }}>
                <img src={WhatsApp} alt="WhatsApp" width="40" />
                <Typography sx={{ fontWeight: '700' }}>9583966999</Typography>
              </a>
            </Box>
          </motion.div>
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={4}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <Box sx={{ p: 2, textAlign: 'center', boxShadow: 3, marginTop: {sm:'80px',xs:"0px"}, backgroundColor:"#fff" }}>
              <a href="tel:+9583966999" style={{ textDecoration: "none", color: "black" }}>
                <img src={Phone} alt="Phone" width="40" />
                <Typography sx={{ fontWeight: '700' }}>9583966999</Typography>
              </a>
            </Box>
          </motion.div>
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={4} >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1.5 }}
          >
            <Box sx={{ p: 2, textAlign: 'center', boxShadow: 3, marginTop: {sm:'80px',xs:"0px"}, backgroundColor:"#fff" }}>
              <a href="mailto:info@jnine.in" style={{ textDecoration: "none", color: "black" }}>
                <img src={Email} alt="Email" width="40" />
                <Typography sx={{ fontWeight: '700', textDecoration: "none", color: "black" }}>info@jnine.in</Typography>
              </a>
            </Box>
          </motion.div>
        </Grid>
      </Grid>

      <Grid item xs={12}>
          <Box
            component="img"
            src={contact} 
            alt="Contact for Website Services"
            sx={{
              width: '100%',
              borderRadius: '8px',
              mt:1,
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              height:{xs:"40vh",sm:"60vh",md:"90vh"}
            }}
          />
        </Grid>

      {/* Form Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2 }}
      >
         {/* Contact Us Form */}
      <Box sx={{ padding: "20px 0" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <Paper sx={{ padding: "20px", boxShadow: "none" }}>
              <Typography
                variant="h3"
                sx={{
                  color: "#000",
                  marginBottom: "20px",
                  fontSize: { md: "48px", xs: "22px" },
                  fontFamily: "Montserrat, Sans-serif",
                  textAlign:"center"
                }}
              >
                Contact Us
              </Typography>

              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      First Name{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter First Name"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      Last Name{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter Last Name"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
                  <Grid item xs={6}>
                    <Typography>
                      Email ID{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter Email ID"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Mobile No.{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter Mobile Number"
                      variant="standard"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
                  <Grid item xs={12}>
                    <Typography>
                      Address{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Queries"
                      variant="standard"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: "#333333",
                          fontWeight: "700",
                          "&:hover": {
                            backgroundColor: "#333333",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      </motion.div>
      {/* Floating Action Button with Social Icons */}
      <div
              ref={ref}
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginBottom: "10px",
                  transition: "all 0.3s ease-in-out",
                  opacity: isOpen ? 1 : 0,
                  transform: isOpen ? "scale(1)" : "scale(0.9)",
                  pointerEvents: isOpen ? "all" : "none",
                }}
              >
                {/* WhatsApp */}
                <Tooltip title="WhatsApp" arrow>
                  <Fab
                    size="small"
                    style={{ backgroundColor: "#25D366", color: "#fff" }}
                    onClick={() => window.open("https://wa.me/919583966999", "_blank")}
                  >
                    <WhatsAppIcon />
                  </Fab>
                </Tooltip>

                {/* Call */}
                <Tooltip title="Call" arrow>
                  <Fab
                    size="small"
                    color="secondary"
                    style={{ backgroundColor: "#FF5722", color: "#fff" }}
                    onClick={() => window.open("tel:+919583966999", "_self")}
                  >
                    <CallIcon />
                  </Fab>
                </Tooltip>

                {/* Instagram */}
                <Tooltip title="Instagram" arrow>
                  <Fab
                    size="small"
                    color="secondary"
                    style={{ backgroundColor: "#E1306C", color: "#fff" }}
                    onClick={() => window.open("https://www.instagram.com/j9infotech/", "_blank")}
                  >
                    <InstagramIcon />
                  </Fab>
                </Tooltip>

                {/* Facebook */}
                <Tooltip title="Facebook" arrow>
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => window.open("https://www.facebook.com/JNineInfotechh/", "_blank")}
                  >
                    <FacebookIcon />
                  </Fab>
                </Tooltip>

                {/* LinkedIn */}
                <Tooltip title="LinkedIn" arrow>
                  <Fab
                    size="small"
                    style={{ backgroundColor: "#0077B5", color: "#fff" }}
                    onClick={() => window.open("https://www.linkedin.com/company/j-nine-infotech", "_blank")}
                  >
                    <LinkedInIcon />
                  </Fab>
                </Tooltip>
              </div>

                {/* Main "+" Button */}
                <Fab
                  color="primary"
                  onClick={toggleIcons}
                  style={{
                    backgroundColor: "#3f51b5",
                    color: "#fff",
                    transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                >
                  <AddIcon />
                </Fab>
              </div>
    </Box>
  );
}

export default NewsletterSubscriptionForm;
