import React, { Component } from "react";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./addes.css";
import { Link } from "react-router-dom";

class BlogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      imageFiles: [],
      previewImages: [],
      heading: "",
      writtenBy: "",
      htmlContent: "",
      previewFiles: [],
    };

    this.modules = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }], // Header options
          ["bold", "italic", "underline", "strike"], // Formatting options
          [{ list: "ordered" }, { list: "bullet" }], // List options
          ["link", "image", "video"], // Link, image, video options
          ["clean"], // Clear formatting
        ],
        handlers: {
          image: this.handleImageUpload,
          video: this.handleVideoUpload,
        },
      },
    };

    this.formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "list",
      "bullet",
      "link",
      "image",
    ];
  }

  handleContentChange = (content) => {
    this.setState({ htmlContent: content });
  };

  handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    console.log("Submitted Data:", this.state); // Log all the current form data

    // Here you can send the data to the parent component or backend
    this.props.SaveBlog(
      this.props.login.token,
      this.props.login.user_id,
      this.state.category,
      this.state.imageFiles,
      this.state.heading,
      this.state.writtenBy,
      this.state.htmlContent
    );

    this.setState({
      category: "",
      imageFiles: [],
      previewImages: [],
      heading: "",
      writtenBy: "",
      htmlContent: "",
      previewFiles: [],
    });
  };
  componentDidMount() {
    this.props.viewAllCategoryWithOutPgn(this.props.login.token);
  }
  handleFileUpload = (event) => {
    const files = Array.from(event.target.files);

    const previewFiles = files.map((file) => {
      const preview = URL.createObjectURL(file);
      return { file, preview };
    });

    this.setState({
      imageFiles: files, // Store the actual file objects
      previewFiles, // Store preview URLs for both images and videos
    });
  };

  handleVideoUpload = () => {
    const type = prompt(
      "Choose video type: \n1. Upload file\n2. Paste video URL"
    );
    if (type === "1") {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "video/*");
      input.click();

      input.onchange = () => {
        const file = input.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const range = this.quillRef.getEditor().getSelection();
            this.quillRef
              .getEditor()
              .insertEmbed(range.index, "video", reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    } else if (type === "2") {
      const url = prompt("Enter the video URL:");
      if (url) {
        const range = this.quillRef.getEditor().getSelection();
        this.quillRef.getEditor().insertEmbed(range.index, "video", url);
      }
    } else {
      alert("Invalid option. Please choose either 1 or 2.");
    }
  };

  handleInputChange = (field, value) => {
    this.setState({ [field]: value });
  };
  handleCancel = () => {
    this.setState({
      category: "",
      imageFiles: [],
      previewImages: [],
      heading: "",
      writtenBy: "",
      htmlContent: "",
    });
    this.props.history.push("/blogpost");
  };

  render() {
    const { previewFiles } = this.state;
    return (
      <Grid
        container
        spacing={3}
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6} lg={6} justifyContent="center">
          <Box
            sx={{
              boxShadow: 5,
              borderRadius: 2,
              p: 4,
              bgcolor: "background.paper",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <Link to="/blogpost">
              <Button
                color="primary"
                variant="outlined"
                style={{
                  marginBottom: "20px",
                  backgroundColor: "#1565C0",
                  color: "white",
                }}
              >
                Back
              </Button>
            </Link>
            <Typography
              variant="h4"
              gutterBottom
              textAlign="center"
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              Add Blogs
            </Typography>
            <Paper elevation={3} style={{ padding: "30px", marginTop: "20px" }}>
              <form onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth style={{ marginBottom: "20px" }}>
                      <InputLabel id="category-label">Category</InputLabel>
                      <Select
                        labelId="category-label"
                        value={this.state.category}
                        onChange={(e) =>
                          this.handleInputChange("category", e.target.value)
                        }
                        label="Category"
                      >
                        {this.props.client.viewAllCategorys.map(
                          (item, index) => {
                            return (
                              <MenuItem value={item._id}>
                                {item.category}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <input
                      accept="image/*,video/*"
                      type="file"
                      multiple
                      onChange={this.handleFileUpload}
                      style={{
                        marginBottom: "20px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        display: "block",
                      }}
                    />
                    {this.state.previewFiles.length > 0 && (
                      <Grid container spacing={2}>
                        {this.state.previewFiles.map((fileData, index) => (
                          <Grid item xs={4} key={index}>
                            {fileData.file.type.startsWith("image") ? (
                              <img
                                src={fileData.preview}
                                alt={`preview-${index}`}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            ) : fileData.file.type.startsWith("video") ? (
                              <video
                                src={fileData.preview}
                                controls
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  borderRadius: "5px",
                                }}
                              />
                            ) : null}
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Heading"
                      fullWidth
                      value={this.state.heading}
                      onChange={(e) =>
                        this.handleInputChange("heading", e.target.value)
                      }
                      style={{ marginBottom: "20px" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Written By"
                      fullWidth
                      value={this.state.writtenBy}
                      onChange={(e) =>
                        this.handleInputChange("writtenBy", e.target.value)
                      }
                      style={{ marginBottom: "20px" }}
                    />
                  </Grid>
                </Grid>
                <ReactQuill
                  ref={(el) => {
                    this.quillRef = el;
                  }}
                  value={this.state.htmlContent}
                  onChange={this.handleContentChange}
                  modules={this.modules}
                  formats={this.formats}
                  theme="snow"
                  placeholder="Write your content here..."
                  style={{
                    height: "300px",
                    marginBottom: "20px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Link to="/blogpost">
                      <Button
                        onClick={this.handleCancel}
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        style={{ padding: "10px 0" }}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <Link to="/blogpost"> */}
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ padding: "10px 0" }}
                      >
                        Save
                      </Button>
                    {/* </Link> */}
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default BlogForm;
