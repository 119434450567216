import * as CONSTANT from "../Auth/Constants.js";
import UNIVERSAL from "../Config/Config.js";
import { setLoader, unsetLoader } from "../Common/loader/action.js";
import { set_snack_bar } from "../Common/snackbar/action.js";
import * as XLSX from "xlsx";
import moment from "moment";
// import 'firebase/storage';
// import { ViewUserDetails } from "../Pages/Profile/Action.js"
// import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
// import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export function loginFetch(email, password) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch({ type: CONSTANT.LOGIN, payload: responseJson.data.result });
          dispatch(setAdminLogin(responseJson));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function getUseByAuthToken(token) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/getUserDetailsAuthByToken", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch({
            type: CONSTANT.PROFILEDEATAILS,
            payload: responseJson.data,
          });
          dispatch(unsetLoader());
        } else {
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function fetchToken(token) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/getUserToken", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(setTokenData(responseJson.data));
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 403 ||
          responseJson.statusCode === 409
        ) {
          dispatch(onLogout());
          dispatch(unsetLoader());
        } else {
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function forgotpasswords(email) {
  return (dispatch) => {
    console.log(email);
    
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/forgotpassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch({
            type: CONSTANT.PASSWORD_GENERATE,
            payload: responseJson.message,
          });
          dispatch(unsetLoader());
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 403
        ) {
          dispatch(onLogout());
          dispatch(unsetLoader());
        } else {
          dispatch({
            type: CONSTANT.PASSWORD_GENERATE,
            payload: responseJson.message,
          });
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function passwordChange(token, user_id, oldpassword, newpassword) {
  console.log(token, user_id, oldpassword, newpassword);

  return (dispatch) => {
    dispatch(setLoader());

    return fetch(UNIVERSAL.BASEURL + "user/changepassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({
        user_id,
        password: oldpassword,
        newpassword,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch(unsetLoader());
          dispatch(onLogout());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function getTestDriveReport(token, startDate, endDate) {
  console.log(token, startDate, endDate);
  return (dispatch) => {
    dispatch(setLoader());

    return fetch(UNIVERSAL.BASEURL + "tdrive/testdrivereport", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token, // Ensure that 'token' is defined in the appropriate scope
      },
      body: JSON.stringify({
        startDate,
        endDate,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
          console.log(responseJson);
          exportToExcel(responseJson.data); // Assuming responseJson.data contains the array of test drive reports
        } else {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

function formatTravelTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${parseInt(remainingSeconds)}s`;
}

function exportToExcel(data) {
  // Format data with conditions to handle missing or undefined fields
  console.log(data);
  const formattedData = data.map((item, index) => ({
    SLNO: index + 1, // Adjust index to be 1-based
    "DATE & TIME": item.createdAt
      ? new Date(item.createdAt).toLocaleString()
      : "",
    "EXECUTIVE NAME": item.taken_by?.name ? item.taken_by.name : "",
    "CUSTOMER NAME": item.taken_user?.name ? item.taken_user.name : "",
    MODEL: item.model?.model ? item.model?.model : "",
    "MODEL TYPE": item.model?.type ? item.model?.type : "",
    "TEST DRIVE DURATION": item.travelTime
      ? formatTravelTime(item.travelTime)
      : "",
    "TEST DRIVE KILLOMETER": item.distance ? item.distance : "",
    "START PLACE NAME": item.startPlaceName ? item.startPlaceName : "",
    "END PLACE NAME": item.endPlaceName ? item.endPlaceName : "",
    STATUS: item.status ? item.status : "",
    "FEEDBACK 1": item.feedback1 ? item.feedback1 : "",
    "FEEDBACK 2": item.feedback2 ? item.feedback2 : "",
    "FEEDBACK 3": item.feedback3 ? item.feedback3 : "",
    "FEEDBACK 4": item.feedback4 ? item.feedback4 : "",
    "FEEDBACK 5": item.feedback5 ? item.feedback5 : "",
    "FEEDBACK 6": item.feedback6 ? item.feedback6 : "",
    "FEEDBACK 7": item.feedback7 ? item.feedback7 : "",
    "FEEDBACK 8": item.feedback8 ? item.feedback8 : "",
    "FEEDBACK 9": item.feedback9 ? item.feedback9 : "",
    "FEEDBACK 10": item.feedback10 ? item.feedback10 : "",
    "FEEDBACK 11": item.feedback11 ? item.feedback11 : "",
  }));

  // Create worksheet and workbook
  const worksheet = XLSX.utils.json_to_sheet(formattedData, {
    header: [
      "SLNO",
      "DATE & TIME",
      "EXECUTIVE NAME",
      "CUSTOMER NAME",
      "MODEL",
      "MODEL TYPE",
      "TEST DRIVE DURATION",
      "TEST DRIVE KILLOMETER",
      "START PLACE NAME",
      "END PLACE NAME",
      "STATUS",
      "FEEDBACK 1",
      "FEEDBACK 2",
      "FEEDBACK 3",
      "FEEDBACK 4",
      "FEEDBACK 5",
      "FEEDBACK 6",
      "FEEDBACK 7",
      "FEEDBACK 8",
      "FEEDBACK 9",
      "FEEDBACK 10",
      "FEEDBACK 11",
    ],
  });

  const workbook = XLSX.utils.book_new();

  // Apply bold formatting to headers
  const headers = [
    "SLNO",
    "DATE & TIME",
    "EXECUTIVE NAME",
    "CUSTOMER NAME",
    "MODEL",
    "MODEL TYPE",
    "TEST DRIVE DURATION",
    "TEST DRIVE KILLOMETER",
    "START PLACE NAME",
    "END PLACE NAME",
    "STATUS",
    "FEEDBACK 1",
    "FEEDBACK 2",
    "FEEDBACK 3",
    "FEEDBACK 4",
    "FEEDBACK 5",
    "FEEDBACK 6",
    "FEEDBACK 7",
    "FEEDBACK 8",
    "FEEDBACK 9",
    "FEEDBACK 10",
    "FEEDBACK 11",
  ];

  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

  // Apply bold formatting to headers
  headers.forEach((header, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    if (!worksheet[cellAddress]) return;
    worksheet[cellAddress].s = {
      font: { bold: true },
    };
  });

  XLSX.utils.book_append_sheet(workbook, worksheet, "Test Drive Report");

  // Write the workbook to a file
  XLSX.writeFile(workbook, "TestDriveReport.xlsx");
}

export function handleDrawerToggle(mobileOpen) {
  mobileOpen = !mobileOpen;
  return {
    type: CONSTANT.HANDLEDRAWER,
    payload: mobileOpen,
  };
}

export function setAdminLogin(payload) {
  payload.data.result.token = payload.data.token;
  localStorage.setItem("token", payload.data.token);
  return {
    type: CONSTANT.LOGIN,
    payload: payload.data.result,
  };
}

export function setTokenData(payload) {
  payload.user_id.token = payload.token;
  localStorage.setItem("token", payload.token);
  return {
    type: CONSTANT.LOGIN,
    payload: payload.user_id,
  };
}

export function onLogout() {
  localStorage.removeItem("persist:root");
  localStorage.clear();
  return {
    type: CONSTANT.LOGOUT,
  };
}
// export function setEditPassword(payload) {
//     return {
//         type: CONSTANT.EDITPASSWORD,
//         payload: payload
//     };
// }
