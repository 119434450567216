import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  Divider,
  IconButton,
  Drawer,
  useMediaQuery,
  CardMedia,
  Grid,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, Padding } from "@mui/icons-material";
import CardCover from "@mui/joy/CardCover";
import { useTheme } from "@mui/material/styles";
import Carasole from "./carasole";
import qr from "../../images/qr.jpg";
import tapx from "../../images/tapx.png";
import mgVideo from "../../Video/mgvideo.mp4";
import kiaVideo from "../../Video/kiaVideo.mp4";
import bydVideo from "../../Video/byd.mp4";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img1 from "../../images/best-web-design-company-logo.webp";
import card1 from "../../images/web.jpg";
import card2 from "../../images/seo.webp";
import card3 from "../../images/ads.webp";
import card4 from "../../images/hosting.webp";
import card5 from "../../images/ecommerce-logo.webp";
import card6 from "../../images/facebook-ads-logo.webp";
import card7 from "../../images/card7.png";
import card8 from "../../images/card8.jpg";
import card9 from "../../images/card9.png";
import card10 from "../../images/card10.png";
import Imagesicon from "./Imagesicon";
import Faqs from "./faq";

import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const AnimatedSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.2, // Triggers animation when 20% of the component is visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, delay }}
    >
      {children}
    </motion.div>
  );
};

function Home() {
  const [open, setOpen] = useState(false);
  // const ref = useRef(null);
  const toggleIcons = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);
 

  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once when the element enters the viewport
    threshold: 0.3, // 30% of the component must be visible to trigger the animation
  });

  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true, // Trigger animation only once when the element enters the viewport
    threshold: 0.3, // 30% of the component must be visible to trigger the animation
  });

  return (
    <Box style={{ overflowX: "hidden", marginTop: "60px" }}>
      <Box
        sx={{
          height: { md: "100vh", sm: "60vh", xs: "30vh" },
          overflow: "hidden",
        }}
      >
        {/* Carrousel section */}
        <Carasole />
      </Box>
      <Box sx={{ backgroundColor: "#0F1214" }}>
        {/* cards */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <AnimatedSection>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h3"
                sx={{
                  textAlign: "center",
                  fontFamily: "Montserrat, Sans-serif",
                  margin: "30px",
                  fontSize: { xs: "25px", sm: "27px", lg: "30px" },
                  fontWeight: "700",
                  color: "white",
                }}
              >
                Welcome to J Nine Infotech - Innovating IT & Software Solutions
                Across India
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <img
                  src={img1}
                  alt="Logo"
                  style={{ height: "20%", width: "100%" }}
                />
              </Box>
            </Box>
          </AnimatedSection>

          {/* Intro Section */}
          <AnimatedSection delay={0.2}>
            <Typography
              sx={{
                color: "white",
                width: "80%",
                textAlign: "start",
                margin: "20px auto",
                paddingX: "5%",
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              At J Nine Infotech, we are your trusted partner for innovative IT
              and software solutions designed to drive business growth and
              success. Established in 2021 and headquartered in Bhubaneswar,
              Odisha, we proudly serve businesses across India, providing
              end-to-end services that include web development, mobile app
              development, custom software solutions, and digital marketing.
            </Typography>
          </AnimatedSection>

          {/* Services Section */}
          <AnimatedSection>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                textAlign: "center",
                fontFamily: "Montserrat, Sans-serif",
                margin: "30px",
                fontSize: { xs: "25px", sm: "27px", lg: "30px" },
                fontWeight: "700",
              }}
            >
              Services Provided by Us
            </Typography>
          </AnimatedSection>

          <Grid
            container
            spacing={3}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: { xs: "16px", sm: "32px", md: "64px" },
              paddingRight: { xs: "16px", sm: "32px", md: "64px" },
            }}
          >
            {[
              {
                title: "Website Development & Design",
                description:
                  "Crafting tailored websites, optimizing for visibility and growth.",
                image: card1,
              },
              {
                title: "Search Engine Optimization",
                description:
                  "Elevating online visibility and rankings for lasting success.",
                image: card2,
              },
              {
                title: "E-Commerce Solutions",
                description:
                  "Elevate your online business with seamless, tailored digital transactions.",
                image: card5,
              },
              {
                title: "Web Hosting",
                description:
                  "Reliable infrastructure ensuring seamless performance and secure online presence.",
                image: card4,
              },
              {
                title: "Social Media Marketing",
                description:
                  "Engaging strategies to amplify your brand presence and grow your audience on social media.",
                image: card7,
              },
              {
                title: "Official Business WhatsApp",
                description:
                  "Streamlining communication with clients and customers via a professional WhatsApp number.",
                image: card8,
              },
              {
                title: "Professional Email",
                description:
                  "Create a professional email address that enhances your brand's credibility and communication.",
                image: card9,
              },
              {
                title: "IVR (Interactive Voice Response)",
                description:
                  "Automate customer service and enhance user experience with a custom IVR solution.",
                image: card10,
              },
              {
                title: "Google Ads",
                description:
                  "Precision campaigns for targeted visibility and impact.",
                image: card3,
              },
              {
                title: "Facebook Ads",
                description:
                  "Strategic campaigns to amplify brand visibility and engagement.",
                image: card6,
              },
            ].map((service, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <AnimatedSection>
                  <Card
                    sx={{
                      borderRadius: "16px",
                      overflow: "hidden",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      padding: "16px",
                      height: "290px", // Make card height 100% of the grid's height
                      marginLeft: "auto", // Centering the card horizontally
                      marginRight: "auto", // Centering the card horizontally
                    }}
                  >
                    <CardMedia
                      component="img"
                      src={service.image}
                      alt={service.title}
                      sx={{
                        borderRadius: "50%",
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        margin: "20px 0",
                      }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        level="h6"
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Montserrat, Sans-serif",
                          fontSize: { xs: "16px", sm: "20px" },
                        }}
                      >
                        {service.title}
                      </Typography>
                      <Typography
                        level="body2"
                        sx={{
                          marginTop: "5px",
                          fontFamily: "Montserrat, Sans-serif",
                          color: "grey",
                          fontSize: { xs: "13px", sm: "14px" },
                        }}
                      >
                        {service.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </AnimatedSection>
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{
              maxWidth: 600,
              p: 3,
              color: "white",
              paddingX: { xs: "7%", sm: "10%" },
            }}
          >
            <AnimatedSection delay={0.2}>
              <Box component="h2" sx={{ mb: 2 }}>
                Why Choose Us?
              </Box>
              <Box component="ul" sx={{ listStyleType: "none", p: 0 }}>
                <Box component="li" sx={{ mb: 1 }}>
                  • Proven expertise in IT and software solutions.
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  • Customer-focused approach to deliver measurable results.
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  • Nationwide reach with personalized support.
                </Box>
              </Box>
            </AnimatedSection>
          </Box>

          {/* content */}
          <AnimatedSection delay={0.2}>
            <Box
              sx={{
                maxWidth: 600,
                p: 3,
                color: "white",
                paddingX: { xs: "7%", sm: "10%" },
              }}
            >
              <Box component="h2" sx={{ mb: 2 }}>
                Why Businesses Across India Trust J Nine Infotech
              </Box>
              <Box component="ul" sx={{ listStyleType: "none", p: 0 }}>
                <Box component="li" sx={{ mb: 1 }}>
                  • <b>Web Development Services: </b>We create visually stunning
                  and highly functional websites optimized for performance and
                  user experience.
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  • <b>Custom Mobile App Development:</b> From Android to iOS,
                  our apps deliver seamless user engagement.
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  • <b>Custom Software Solutions:</b> Scalable and efficient
                  solutions to streamline operations and improve productivity.
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  • <b>Comprehensive Digital Marketing:</b> SEO, social media,
                  and performance marketing to grow your brand and expand your
                  reach.
                </Box>
              </Box>
            </Box>
          </AnimatedSection>

          {/* service and product */}

          <Box
            sx={{
              padding: "20px",
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            {/* Product Section */}
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "30px",
                fontFamily: "Montserrat, Sans-serif",
                color: "#fff",
              }}
            >
              Our Product
            </Typography>

            {/* Product Cards */}
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              {/* Tapx */}
              <Grid container spacing={4} justifyContent="center">
                {/* Tapx Card */}
                <Grid
                  container
                  item
                  xs={12}
                  sm={10}
                  md={8}
                  spacing={2}
                  sx={{
                    borderRadius: "10px",
                    boxShadow: 3,
                    overflow: "hidden",
                  }}
                  direction="row"
                  alignItems="center"
                  ref={ref}
                >
                  {/* Animated Image */}
                  <Grid item xs={12} sm={5}>
                    <motion.img
                      src={qr}
                      alt="Tapx"
                      initial={{ x: "100%", opacity: 0 }}
                      animate={{
                        x: inView ? 0 : "100%",
                        opacity: inView ? 1 : 0,
                      }}
                      transition={{ duration: 0.8 }}
                      style={{
                        width: "80%",
                        height: "auto",
                        objectFit: "cover",
                        borderRadius: "8px",
                        marginLeft: "8%",
                      }}
                    />
                  </Grid>

                  {/* Animated Content */}
                  <Grid item xs={12} sm={7}>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: inView ? 1 : 0 }}
                      transition={{ delay: 0.5, duration: 0.8 }}
                    >
                      <CardContent
                        sx={{
                          textAlign: "left",
                          fontFamily: "Montserrat, Sans-serif",
                          marginLeft: "8%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="h2"
                          sx={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontFamily: "Montserrat, Sans-serif",
                            color: "#fff",
                          }}
                        >
                          QR Code
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontFamily: "Montserrat, Sans-serif",
                            color: "#fff",
                            width: { xs: "90%", sm: "100%" },
                          }}
                        >
                          A QR code is a versatile tool that stores and shares
                          information quickly. It can link to websites,
                          documents, contact details, or even Wi-Fi networks.
                          Simply scan it with your smartphone or a QR code
                          reader to access the content instantly. QR codes
                          eliminate the need for typing URLs or searching for
                          information manually. They are widely used in
                          marketing, business cards, event registrations, and
                          much more. This efficient technology bridges the gap
                          between the physical and digital world with ease.
                        </Typography>
                      </CardContent>
                    </motion.div>
                  </Grid>
                </Grid>

                {/* Autotracker Card */}

                <Grid
                  container
                  item
                  xs={12}
                  sm={10}
                  md={8}
                  spacing={2}
                  sx={{
                    borderRadius: "10px",
                    boxShadow: 3,
                    overflow: "hidden",
                  }}
                  direction="row-reverse"
                  alignItems="center"
                  ref={ref1}
                >
                  {/* Animated Image */}
                  <Grid item xs={12} sm={5}>
                    <motion.img
                      src={tapx}
                      alt="Tapx Digital"
                      initial={{ x: "-100%", opacity: 0 }}
                      animate={{
                        x: inView1 ? 0 : "100%",
                        opacity: inView1 ? 1 : 0,
                      }}
                      transition={{ duration: 0.8 }}
                      style={{
                        width: "80%",
                        height: "auto",
                        objectFit: "cover",
                        borderRadius: "8px",
                        marginLeft: "8%",
                      }}
                    />
                  </Grid>

                  {/* Animated Content */}
                  <Grid item xs={12} sm={7}>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: inView1 ? 1 : 0 }}
                      transition={{ delay: 1, duration: 0.8 }}
                    >
                      <CardContent
                        sx={{
                          textAlign: "left",
                          fontFamily: "Montserrat, Sans-serif",
                          marginLeft: "8%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="h2"
                          sx={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontFamily: "Montserrat, Sans-serif",
                            color: "#fff",
                          }}
                        >
                          Tapx Digital
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontFamily: "Montserrat, Sans-serif",
                            color: "#fff",
                            width: { xs: "90%", sm: "100%" },
                          }}
                        >
                          Tapx is our revolutionary product that streamlines
                          communication and productivity in your team. With its
                          intuitive interface, team members can collaborate more
                          efficiently and stay connected in real time,
                          regardless of their location. Tapx integrates
                          seamlessly with your existing workflow, making it
                          easier than ever to share information and keep
                          projects moving forward.
                        </Typography>
                      </CardContent>
                    </motion.div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Projects Section */}
            <AnimatedSection delay={0.4}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  margin: "40px 0 20px",
                  fontFamily: "Montserrat, Sans-serif",
                  color: "#fff",
                }}
              >
                Our Projects
              </Typography>

              {/* Services Cards */}
              <Grid container spacing={4} justifyContent="center">
                {/* Service 1 */}
                <Grid item xs={12} sm={4} md={3.5}>
                  <Card
                    sx={{
                      borderRadius: "10px",
                      boxShadow: 3,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      component="video"
                      height="280"
                      src={mgVideo}
                      alt="MG Central"
                      playsInline
                      autoPlay
                      loop
                      muted
                      preload="auto"
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                    <CardContent
                      sx={{ flexGrow: 1, fontFamily: "Montserrat, Sans-serif" }}
                    >
                      <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontFamily: "Montserrat, Sans-serif",
                        }}
                      >
                        MG Central
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          textAlign: "center",
                          fontFamily: "Montserrat, Sans-serif",
                        }}
                      >
                        Web address:{" "}
                        <a
                          href="https://www.mgcentral.in/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            fontWeight: "bold",
                          }}
                        >
                          Mg Central
                        </a>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Service 2 */}
                <Grid item xs={12} sm={4} md={3.5}>
                  <Card
                    sx={{
                      borderRadius: "10px",
                      boxShadow: 3,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      component="video"
                      height="280"
                      src={kiaVideo} // Replace 'kia' with your video file path or URL
                      alt="Central Kia"
                      playsInline
                      autoPlay
                      loop
                      muted
                      preload="auto"
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                    <CardContent
                      sx={{ flexGrow: 1, fontFamily: "Montserrat, Sans-serif" }}
                    >
                      <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontFamily: "Montserrat, Sans-serif",
                        }}
                      >
                        Central Kia
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          textAlign: "center",
                          fontFamily: "Montserrat, Sans-serif",
                        }}
                      >
                        Web address:{" "}
                        <a
                          href="https://centralkia.co.in"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            fontWeight: "bold",
                          }}
                        >
                          Central Kia
                        </a>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Service 3 */}
                <Grid item xs={12} sm={4} md={3.5}>
                  <Card
                    sx={{
                      borderRadius: "10px",
                      boxShadow: 3,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      component="video"
                      height="280"
                      src={bydVideo} // Replace 'byd' with your video file path or URL
                      alt="BYD Central"
                      playsInline
                      autoPlay
                      loop
                      muted
                      preload="auto"
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                    <CardContent
                      sx={{ flexGrow: 1, fontFamily: "Montserrat, Sans-serif" }}
                    >
                      <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontFamily: "Montserrat, Sans-serif",
                        }}
                      >
                        BYD Central
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          textAlign: "center",
                          fontFamily: "Montserrat, Sans-serif",
                        }}
                      >
                        Web address:{" "}
                        <a
                          href="https://www.centralbyd.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            fontWeight: "bold",
                          }}
                        >
                          BYD Central
                        </a>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </AnimatedSection>
          </Box>

          <Typography
            variant="body1"
            sx={{
              color: "white",
              width: "85%",
              paddingX: { xs: 2, sm: 15 },
              marginBottom: "10px",
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Take your business to the next level with J Nine Infotech, where
            innovation meets excellence. Contact us today for reliable and
            scalable IT solutions, no matter where you are in India! <br />
          </Typography>
        </Box>
      </Box>
      <Imagesicon />
      <Box sx={{ backgroundColor: "#0F1214" }}>
        <Faqs />
      </Box>
      <div style={{ position: "relative" }}>

      {/* Floating Action Button with Social Icons */}
      <div
        ref={ref}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
            transition: "all 0.3s ease-in-out",
            opacity: open ? 1 : 0,
            transform: open ? "scale(1)" : "scale(0.9)",
            pointerEvents: open ? "all" : "none",
          }}
        >
          {/* WhatsApp */}
          <Tooltip title="WhatsApp" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#25D366", color: "#fff" }}
              onClick={() => window.open("https://wa.me/919583966999", "_blank")}
            >
              <WhatsAppIcon />
            </Fab>
          </Tooltip>

          {/* Call */}
          <Tooltip title="Call" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#FF5722", color: "#fff" }}
              onClick={() => window.open("tel:+919583966999", "_self")}
            >
              <CallIcon />
            </Fab>
          </Tooltip>

          {/* Instagram */}
          <Tooltip title="Instagram" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#E1306C", color: "#fff" }}
              onClick={() => window.open("https://www.instagram.com/j9infotech/", "_blank")}
            >
              <InstagramIcon />
            </Fab>
          </Tooltip>

          {/* Facebook */}
          <Tooltip title="Facebook" arrow>
            <Fab
              size="small"
              color="primary"
              onClick={() => window.open("https://www.facebook.com/JNineInfotechh/", "_blank")}
            >
              <FacebookIcon />
            </Fab>
          </Tooltip>

          {/* LinkedIn */}
          <Tooltip title="LinkedIn" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#0077B5", color: "#fff" }}
              onClick={() => window.open("https://www.linkedin.com/company/j-nine-infotech", "_blank")}
            >
              <LinkedInIcon />
            </Fab>
          </Tooltip>
        </div>

        {/* Main "+" Button */}
        <Fab
          color="primary"
          onClick={toggleIcons}
          style={{
            backgroundColor: "#3f51b5",
            color: "#fff",
            transform: open ? "rotate(45deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <AddIcon />
        </Fab>
      </div>
    </div>
    </Box>
  );
}

export default Home;
