import React, { useState, useRef , useEffect} from "react";
import { Typography, Box, Card, CardContent,List, ListItem, Divider, IconButton, Drawer, useMediaQuery,CardMedia,Grid,ListItemText } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos,  } from "@mui/icons-material";
import CardCover from '@mui/joy/CardCover';
import { useTheme } from "@mui/material/styles";
// import video1 from '../../Video/tours.mp4'
// import video2 from '../../Video/ngo.mp4'
// import video3 from '../../Video/business.mp4'
import img1 from '../../images/best-web-design-company-logo.webp'
import card1 from '../../images/coding.webp'
import card2 from '../../images/seo.webp';
import card3 from '../../images/ads.webp';
import card4 from '../../images/hosting.webp';
import card5 from '../../images/ecommerce-logo.webp'
import card6 from '../../images/facebook-ads-logo.webp';
import card7 from '../../images/card7.png';
import card8 from '../../images/card8.jpg';
import card9 from '../../images/card9.png';
import card10 from '../../images/card10.png'
import domain from '../../images/domain.webp'
import domainprice from '../../images/domainprice.webp';
import nameprice from '../../images/namecheapprice.webp';
import hosting from '../../images/hosting1.webp';
import rightwebsite from '../../images/rightwebsite.jpg';
import cpanel from '../../images/cpanel.webp';
import background from '../../images/price.jpg';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const AnimatedSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.2,    // Triggers animation when 20% of the component is visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, delay }}
    >
      {children}
    </motion.div>
  );
};
function Pricing() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [cardHeight, setCardHeight] = useState(0);
    const cardRef = useRef(null);
  
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const visibleCards = isDesktop ? 4 : isTablet ? 2 : 1;
  

  const cardData = [
    {
      title: "Bronze Website Development",
      subtitle: "One Page Website",
      price: "₹24,999",
      features: [
        "One Page Website",
        "Free Domain 1 Year*",
        "Free Hosting 1 Year",
        "Contact Form",
        "Responsive Design",
        "Professional Email Id 2",
      ],
      contact: "+9583966999",
    },
    {
      title: "Silver Website Development",
      subtitle: "Multi Page Website",
      price: "₹34,999",
      features: [
        "7 Pages Business website",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email Id 10",
        "SSL Certificate",
        "Control Panel"
      ],
      contact: "+9583966999",
    },
    {
      title: "Gold Website Development",
      subtitle: "E-commerce Website",
      price: "₹54,999",
      features: [
        "E-commerce Website",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email 10",
        "SSL Certificate",
        "Control Panel"
      ],
      contact: "+9583966999",
    },
    {
      title: "Gold+ Website Development",
      subtitle: "E-commerce Website",
      price: "₹99,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email 15",
      ],
      contact: "+9583966999",
    },
    {
      title: "Platinum Website Development",
      subtitle: "Advanced E-commerce Website + Android App",
      price: "₹139,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Android Hybrid App",
        "Google Play Publishing",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
      ],
      contact: "+9583966999",
    },
    {
      title: "Diamond Website Development",
      subtitle: "Advanced E-commerce Website + iOs Hybrid App",
      price: "₹149,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Android Hybrid App",
        "Google Play Publishing",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
      ],
      contact: "+9583966999",
    },
  ];
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cardData.length - visibleCards));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < cardData.length - visibleCards ? prevIndex + 1 : 0));
  };

  const [isOpen, setIsOpen] = useState(false); 
     const ref = useRef(null);
   
     const toggleIcons = () => {
       setIsOpen((prev) => !prev);
     };
   
     const handleClickOutside = (event) => {
       if (ref.current && !ref.current.contains(event.target)) {
         setIsOpen(false);
       }
     };
   
     useEffect(() => {
       if (isOpen) {
         document.addEventListener("mousedown", handleClickOutside);
       } else {
         document.removeEventListener("mousedown", handleClickOutside);
       }
   
       return () => {
         document.removeEventListener("mousedown", handleClickOutside);
       };
     }, [isOpen]);
     

  return (
    <div style={{}}>
      
      {/* Videos */}
      <Box sx={{backgroundColor:'#0F1214',overflow:'hidden',marginTop:"60px"}}>
      <Box
        sx={{
          height: { md: "80vh", sm: "60vh", xs: "40vh" },
          minHeight: "400px",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center", 
          position: "relative",
          textAlign: "center",
          color:"white",
          paddingLeft: { md: "5%", sm: "4%", xs: "3%" },  
        }}
      >
        <AnimatedSection>
        <Typography 
          variant="h2" 
          component="h1" 
          sx={{
            fontWeight: "bold",
            color:'#54595F',fontFamily:"Montserrat, Sans-serif",fontSize:{xs:'37px',sm:'37px',lg:'50px'},
            color:'white'
          }}
        >
          Pricing & Plans
        </Typography>
        </AnimatedSection>
      </Box>

      {/* Carousel Section */}
      <AnimatedSection delay={0.2}>
      <Box display='flex' flexDirection="column" justifyContent='center'>
      <Typography variant="h3" sx={{textAlign:'center',color:'#54595F',fontFamily:"Montserrat, Sans-serif",fontSize:{xs:'25px',sm:'27px',lg:'30px'},fontWeight:'700',color:'white',paddingTop:"15px"}}>Pricing Plan for Website</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{alignItems:"center",overflowX:'hidden'}} p={2}>
        <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
          <ArrowBackIos sx={{color:'white'}} />
        </IconButton>

        <Box display="flex" gap={2} sx={{ overflow: "hidden", width: "80%" }}>
          {cardData.slice(currentIndex, currentIndex + visibleCards).map((card, index) => (
            <Card key={index} sx={{ width: "300px", backgroundColor: "#000", color: "#fff", border: "1px solid #00f", borderRadius: "8px" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff", fontWeight: 'bolder' }}>
                  {card.title}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "#bbb" }}>
                  {card.subtitle}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold", margin: "16px 0" }}>
                  {card.price}
                </Typography>
                <List dense sx={{ color: "#ccc" }}>
                  {card.features.map((feature, idx) => (
                    <ListItem key={idx} sx={{ padding: 0, fontSize: "14px" }}>
                      • {feature}
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ backgroundColor: "#333", margin: "16px 0" }} />
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  TALK TO US
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: "8px" }}>
                  {card.contact}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <IconButton onClick={handleNext} disabled={currentIndex === cardData.length - visibleCards}>
          <ArrowForwardIos sx={{color:'white'}} />
        </IconButton>
      </Box>
      </Box>
      </AnimatedSection>

      {/* E-commerce site development */}
      <AnimatedSection delay={0.4}>
      <Box display='flex' flexDirection="column" justifyContent='center'>
        <Typography variant="h3" sx={{
            textAlign: 'center',
            color: '#54595F',
            fontFamily: "Montserrat, Sans-serif",
            fontSize: { xs: '25px', sm: '27px', lg: '30px' },
            fontWeight: '700',
            color: 'white',
            marginTop: '20px'
            }}>
            E-commerce Website Development Pricing
        </Typography>     
        <Grid container justifyContent="center" spacing={4} mt={4}>
            {/* Gold Plan Card */}
            <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ width: '100%', background: 'linear-gradient(135deg, #FFD700, #FFC700)', color: '#333' }}>
                <CardContent>
                <Typography variant="h5" align="center" fontWeight="bold">
                Gold E-Com
                </Typography>
                <Typography variant="h4" align="center" mt={2} fontWeight="bold">
                ₹ 60000
                </Typography>
                <Typography variant="body1" mt={3}>
                    - Sale upto 50 Products Online (Limited to 50 Product Listing, with server upgrade product limit can be increase )<br />
                    - UPI Payment Gateway (Gpay, Paytm, Phone Pe Etc.)<br />
                    - Cash on Delivery Option<br />
                    -Coupon Code Discount System<br/>
                    -Variable Products (Product Size, Color, Etc. )<br/>
                    -GST Auto Calculate<br/>
                    -4 Business Email Id<br/>
                    -Unlimited images and videos<br/>
                    -Free SSL Certificate<br/>
                    -100% Responsive Design<br/>
                    -WhatsApp Chat Integration <br/>
                    -Call Button Integration<br/>
                    -Inquiry Form<br/>
                    -24/7 Support (Phone/Email/Chat)<br/>
                    -Social Media Integration<br/>
                    -SEO Friendly Website<br/>
                    -Admin Access<br/>
                </Typography>
                </CardContent>
            </Card>
            </Grid>

            {/* Platinum Plan Card */}
            <Grid item xs={12} sm={6} md={4}>
            <Card sx={{
                width: '100%',
                backgroundColor: '#000',
                color: '#FFF',
                border: '2px solid #00BFFF',
            }}>
                <CardContent>
                <Typography variant="h5" align="center" fontWeight="bold">
                Platinum E-com
                </Typography>
                <Typography variant="h4" align="center" mt={2} fontWeight="bold">
                ₹ 75000
                </Typography>
                <Typography variant="body1" mt={3}>
                    - All Gold E-com Features Included <br />
                    - Sale Unlimited Products <br />
                    - Upto 20 Business Email ID (Like info@yourcompany.com etc.)<br />
                    -Multi Vendor Features Available (Like Amazon and Flipkart)<br/>
                    -Front End Vendor/Seller dashboard<br/>
                    -Seller Product Adding Feature from Front End<br/>
                    -Customize product before ordering (Like Your Brand Name on t-Shirt, your logo attach)<br/>
                    -Existing Google My Business Profile Integration with Website<br/>
                    -Partial Cash on Delivery option (order will be on COD but a Partial amount customer will pay and rest at delivery)<br/>
                    -Advance Payment Gateway (UPI,Net Banking, Cards, EMI Setup Ability, Accept International Payments)<br/>
                    -1 Free Domain (.Com,.in,.org,etc.)<br/>
                    -1 Year Premium Web Hosting Free<br/>            
                </Typography>
                </CardContent>
            </Card>
            </Grid>
        </Grid>
       </Box>
      </AnimatedSection>

        {/* cards */}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <AnimatedSection delay={0.2}>
        <Typography 
            variant="h4" 
            sx={{
            color: 'white',
            textAlign: 'center',
            fontFamily: "Montserrat, Sans-serif",
            margin: '30px',
            fontSize: { xs: '25px', sm: '27px', lg: '30px' },
            fontWeight: '700'
            }}
        >
            Services This Website Design Company Provide
        </Typography>

        <Grid
    container
    spacing={3}
    sx={{
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: { xs: "16px", sm: "32px", md: "64px" }, 
      paddingRight: { xs: "16px", sm: "32px", md: "64px" }, 
    }}
  >
    {[
      {
        title: "Website Development & Design",
        description:
          "Crafting tailored websites, optimizing for visibility and growth.",
        image: card1,
      },
      {
        title: "Search Engine Optimization",
        description: "Elevating online visibility and rankings for lasting success.",
        image: card2,
      },
      {
        title: "E-Commerce Solutions",
        description:
          "Elevate your online business with seamless, tailored digital transactions.",
        image: card5,
      },
      {
        title: "Web Hosting",
        description:
          "Reliable infrastructure ensuring seamless performance and secure online presence.",
        image: card4,
      },
      {
        title: "Social Media Marketing",
        description:
          "Engaging strategies to amplify your brand presence and grow your audience on social media.",
        image: card7, 
      },
      {
        title: "Official Business WhatsApp",
        description:
          "Streamlining communication with clients and customers via a professional WhatsApp number.",
        image: card8, 
      },
      {
        title: "Professional Email",
        description:
          "Create a professional email address that enhances your brand's credibility and communication.",
        image: card9, 
      },
      {
        title: "IVR (Interactive Voice Response)",
        description:
          "Automate customer service and enhance user experience with a custom IVR solution.",
        image: card10, 
      },      
      {
        title: "Google Ads",
        description: "Precision campaigns for targeted visibility and impact.",
        image: card3,
      },
      {
        title: "Facebook Ads",
        description:
          "Strategic campaigns to amplify brand visibility and engagement.",
        image: card6,
      },
    ].map((service, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Card
          sx={{
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: "16px",
            height: "290px", // Make card height 100% of the grid's height
            marginLeft: "auto", // Centering the card horizontally
            marginRight: "auto", // Centering the card horizontally
          }}
        >
          <CardMedia
            component="img"
            src={service.image}
            alt={service.title}
            sx={{
              borderRadius: "50%",
              width: "80px",
              height: "80px",
              objectFit: "cover",
              margin: "20px 0",
            }}
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography
              level="h6"
              sx={{
                fontWeight: "bold",
                fontFamily: "Montserrat, Sans-serif",
                fontSize: { xs: "16px", sm: "20px" },
              }}
            >
              {service.title}
            </Typography>
            <Typography
              level="body2"
              sx={{
                marginTop: "5px",
                fontFamily: "Montserrat, Sans-serif",
                color: "grey",
                fontSize: { xs: "13px", sm: "14px" },
              }}
            >
              {service.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
        </AnimatedSection>
        <AnimatedSection delay={0.2}>
        <Typography variant='body1' sx={{color:'white',marginTop:'30px',paddingX:{xs:2,sm:6},width:'85%'}}>Now that you know the best website Design Company in India, this is not enough for you to go and hire directly, before that you should know some of the basic knowledge about website development and process because in the market there are lots of fraud which you may face, if something like that happen you will know that what’s the process and they will not be able to fool you because i am assuming most of you are new or startup that’s why you are looking to build a website to grow your brand digitally and its a crucial time for any company in terms of budget also. So if you have the basic knowledge about what are building they it can save up to no limit.</Typography>
        </AnimatedSection></Box>
        {/* Website design steps */}
        <Box sx={{color:'white' }}>
        <AnimatedSection delay={0.4}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff',padding:{xs:2,sm:6},width:"85%",fontFamily: "Montserrat, Sans-serif",}}>
        Steps of Website Development - 4 Basic Steps of Website Development You Should Know
      </Typography>
      </AnimatedSection>
      <AnimatedSection delay={0.4}>
      <Typography variant="body1" sx={{ width:"85%", paddingX:{xs:2,sm:6} }}>
        Although you are hiring a developer to develop your website, you should still know these 4 basic steps before hiring a website builder. Here they are:
      </Typography>

      <List sx={{  paddingX:{xs:2,sm:6}}}>
        <ListItem>
          <ListItemText primary="1. Domain Registration – How to do and How much it costs?" />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Selecting the Right Hosting Plan for Your Website" />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Select the Right Website Builder or Developer" />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. Get Access to Cpanel and Admin Panel" />
        </ListItem>
      </List>
      </AnimatedSection>

      <Typography variant="body1" sx={{  paddingX:{xs:2,sm:6} }}>
        Let me explain to you in detail why you should know these steps before hiring.
      </Typography>

      {/* Domain */}
      <AnimatedSection delay={0.4}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign:"center",margin:"10px",fontFamily: "Montserrat, Sans-serif",}}>
        Domain Registration - How to do and How much does it cost?
      </Typography>

      <Box
        component="img"
        src={domain} 
        alt="Domain Registration"
        sx={{ width: '100%', height: 'auto', display: 'block', mx: 'auto' }}
      />
      <Typography variant="body1" sx={{ mb: 2,marginTop:'12px',paddingX:{xs:2,sm:6} }}>
      Domain registration is the first thing you need to do before building your website don’t give this permission or control to any other person because its your brand it should be reflect you and reflect your brand and you know well about your brand than your developer. Also if you give this control to developer they might register it on their email or on their dashboard this will be a blounder because, think after 5 to 10 years. Imagine your brand become a million dollar brand then if your domain is with any other person , he can blackmail you because he has your brand. 
      </Typography>
      <Typography variant="body1" sx={{mb:2,paddingX:{xs:2,sm:6}}}>
      Other reason that you should know is developer might charge additional if you don’t know how to check the original price of the domain. Its very simple there are lots of website in internet where you can check the domain availability just type Domain registration on google and you will see plenty of websites where you can register your domain.
      </Typography>
      </AnimatedSection>
      <AnimatedSection delay={0.4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <Box
            component="img"
            src={domainprice}
            alt="Image 1"
            sx={{ width: '100%', height: 'auto' }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <Box
            component="img"
            src={nameprice} 
            alt="Image 2"
            sx={{ width: '100%', height: 'auto' }}
            />
        </Grid>
       </Grid>

       <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 ,margin:'13px',textAlign:"center"}}>
       Selecting the Right Hosting Plan for Your Website
      </Typography>

      <Box
        component="img"
        src={hosting} 
        alt="Domain Registration"
        sx={{ width: '100%', height: 'auto', display: 'block', mx: 'auto' }}
      />
      <Typography variant="body1" sx={{ mb: 2,marginTop:'12px',paddingX:{xs:2,sm:6} }}>
      Just like domain hosting also the most important thing in the website because the hosting is the only one who keeps up your website 24hours in the internet , just like domain hosting also you should have the full control because anything goes wrong in the website you can easily tell to your hosting provider and they will restore the website any time or if you know to backup that’s bread and butter but for that too you will need the hosting access.
      </Typography>
      <Typography variant="body1" sx={{mb:2,paddingX:{xs:2,sm:6}}}>
      Another reason that you should know is developer might charge additional if you don’t know how to check the original price of the website, its very simple similar to domain. 
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 ,margin:'13px',textAlign:"center"}}>
      Select Right Website Designer or Developer.
      </Typography>

      <Box
        component="img"
        src={rightwebsite} 
        alt="right website"
        sx={{ width: '100%', height: 'auto', display: 'block', mx: 'auto' }}
      />
      <Typography variant="body1" sx={{ mb: 2,marginTop:'12px',paddingX:{xs:2,sm:6} }}>
      I have mention above that why you should choose the right website Designer or developer for the website development it can save your money and build you better brand visibility on internet. Also i have suggest you the best website builder in Hyderabad too with all details why you should choose them.
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 ,margin:'13px',textAlign:"center"}}>
      Get the Website Cpanel and Admin Panel from Developer
      </Typography>

      <Box
        component="img"
        src={cpanel} 
        alt="right website"
        sx={{ width: '100%', height: 'auto', display: 'block', mx: 'auto' }}
      />
      </AnimatedSection>
      <AnimatedSection delay={0.4}>
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="body1" sx={{paddingX:{xs:2,sm:6},marginTop:"10px"}}>
                Access to cPanel (Control Panel) and the Admin Panel (or Dashboard) is crucial for website owners because these tools provide them with control and management capabilities over their website. Here’s why access to these panels is important:
            </Typography>
        </Grid>

        <Grid item xs={12}>
            <Typography variant="h6" sx={{paddingX:{xs:2,sm:6},fontWeight:"bold"}}>1. Website Management:</Typography>
            <Typography variant="body2" sx={{paddingX:{xs:3,sm:7}}}>
                cPanel and Admin Panel allow website owners to manage various aspects of their website, such as adding or removing email accounts, managing files, installing applications, and monitoring website performance.
            </Typography>
        </Grid>

        <Grid item xs={12}>
            <Typography variant="h6" sx={{paddingX:{xs:2,sm:6},fontWeight:"bold"}}>2. Content Management:</Typography>
            <Typography variant="body2" sx={{paddingX:{xs:3,sm:7}}}>
               Admin Panel typically includes a Content Management System (CMS) where website owners can add, edit, and delete content on their website without needing to know coding languages like HTML or CSS.
            </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{paddingX:{xs:2,sm:6},fontWeight:"bold"}}>3. Security:</Typography>
            <Typography variant="body2" sx={{paddingX:{xs:3,sm:7}}}>
                With access to cPanel, website owners can manage security settings, such as setting up SSL certificates, configuring firewalls, and monitoring for security threats.
            </Typography>
        </Grid>

        <Grid item xs={12}>
           <Typography variant="h6" sx={{paddingX:{xs:2,sm:6},fontWeight:"bold"}}>4. Email Management:</Typography>
            <Typography variant="body2" sx={{paddingX:{xs:3,sm:7}}}>
               cPanel allows users to create and manage email accounts associated with their domain, set up email forwarding, and configure spam filters.
            </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{paddingX:{xs:2,sm:6},fontWeight:"bold"}}>5. Backup and Restore:</Typography>
           <Typography variant="body2" sx={{paddingX:{xs:3,sm:7}}}>
             Both cPanel and Admin Panel often include features for backing up website data and restoring it in case of data loss or website issues.
            </Typography>
        </Grid>

        <Grid item xs={12}>
           <Typography variant="h6" sx={{paddingX:{xs:2,sm:6},fontWeight:"bold"}}>6. Domain Management:</Typography>
            <Typography variant="body2" sx={{paddingX:{xs:3,sm:7}}}>
              Users can manage domain settings, including adding or removing domains, subdomains, and redirects.
            </Typography>
        </Grid>

        <Grid item xs={12}>
           <Typography variant="body1" sx={{paddingX:{xs:2,sm:6}}}>
                Overall, access to cPanel and Admin Panel is essential for website owners to effectively manage and maintain their website’s functionality, security, and content.
            </Typography>
        </Grid>
    </Grid>
    </AnimatedSection>
    </Box>
    {/* Floating Action Button with Social Icons */}
    <div
              ref={ref}
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginBottom: "10px",
                  transition: "all 0.3s ease-in-out",
                  opacity: isOpen ? 1 : 0,
                  transform: isOpen ? "scale(1)" : "scale(0.9)",
                  pointerEvents: isOpen ? "all" : "none",
                }}
              >
                {/* WhatsApp */}
                <Tooltip title="WhatsApp" arrow>
                  <Fab
                    size="small"
                    style={{ backgroundColor: "#25D366", color: "#fff" }}
                    onClick={() => window.open("https://wa.me/919583966999", "_blank")}
                  >
                    <WhatsAppIcon />
                  </Fab>
                </Tooltip>

                {/* Call */}
                <Tooltip title="Call" arrow>
                  <Fab
                    size="small"
                    color="secondary"
                    style={{ backgroundColor: "#FF5722", color: "#fff" }}
                    onClick={() => window.open("tel:+919583966999", "_self")}
                  >
                    <CallIcon />
                  </Fab>
                </Tooltip>

                {/* Instagram */}
                <Tooltip title="Instagram" arrow>
                  <Fab
                    size="small"
                    color="secondary"
                    style={{ backgroundColor: "#E1306C", color: "#fff" }}
                    onClick={() => window.open("https://www.instagram.com/j9infotech/", "_blank")}
                  >
                    <InstagramIcon />
                  </Fab>
                </Tooltip>

                {/* Facebook */}
                <Tooltip title="Facebook" arrow>
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => window.open("https://www.facebook.com/JNineInfotechh/", "_blank")}
                  >
                    <FacebookIcon />
                  </Fab>
                </Tooltip>

                {/* LinkedIn */}
                <Tooltip title="LinkedIn" arrow>
                  <Fab
                    size="small"
                    style={{ backgroundColor: "#0077B5", color: "#fff" }}
                    onClick={() => window.open("https://www.linkedin.com/company/j-nine-infotech", "_blank")}
                  >
                    <LinkedInIcon />
                  </Fab>
                </Tooltip>
              </div>

                {/* Main "+" Button */}
                <Fab
                  color="primary"
                  onClick={toggleIcons}
                  style={{
                    backgroundColor: "#3f51b5",
                    color: "#fff",
                    transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                >
                  <AddIcon />
                </Fab>
              </div>
      </Box>
      
    </div>
  );
}

export default Pricing;
