import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LodarCont from "../../Common/loader/containers/loader_cont";

export default function Overview(props) {
  const [jobType, setjobType] = useState(props.client.viewjobsById.jobType);
  const [skills, setSkills] = useState([props.client.viewjobsById.skills]);
  const [Interntype, setInterntype] = useState(
    props.client.viewjobsById.Interntype
  );
  const [Price, setPrice] = useState(props.client.viewjobsById.Price);
  const [designation, setDesignation] = useState(
    props.client.viewjobsById.designation
  );
  const [location, setLocation] = useState(props.client.viewjobsById.location);
  const [experience, setExperience] = useState(
    props.client.viewjobsById.experience
  );
  const [days, setDays] = useState(props.client.viewjobsById.days);
  const { id } = useParams();
  const { jobTypes } = useParams();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    props.editJobs(
      props.login.token,
      props.login.user_id,
      id,
      jobType,
      designation,
      location,
      skills,
      Interntype,
      Price,
      experience,
      days
    );
    navigate("/job");
    // Add further form submission logic here
  };

  useEffect(() => {
    if (props.viewJobsById) {
      props.viewJobsById(props.login.token, props.login.user_id, id);
    }
  }, [props.login.token, props.login.user_id, id]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{ marginTop: "120px", marginBottom: "30px" }}
    >
      <Grid item xs={12} md={8} lg={6}>
        <Card
          sx={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h5"
                sx={{
                  marginBottom: 3,
                  fontWeight: "bold",
                  color: "#333333",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: { md: "24px", sm: "22px", xs: "20px" },
                }}
              >
                Edit Job Post Details
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="job-type-label">Job Type</InputLabel>
                    <Select
                      labelId="job-type-label"
                      id="job-type-select"
                      value={jobType}
                      onChange={(e) => {
                        setjobType(e.target.value);
                      }}
                      label="Job Type"
                    >
                      <MenuItem value="Full-Time">Full-Time</MenuItem>
                      <MenuItem value="Part-Time">Part-Time</MenuItem>
                      <MenuItem value="Internship">Internship</MenuItem>
                      <MenuItem value="Remote">Remote</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {jobTypes !== "Internship" ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Designation"
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Enter job designation"
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Location"
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Enter job location"
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="skills-label">Skills</InputLabel>
                        <Select
                          labelId="skills-label"
                          id="skills-select"
                          multiple
                          value={skills} // Bind the selected skills state here
                          onChange={(e) => setSkills(e.target.value)} // Handle the change
                          label="Skills"
                          renderValue={(selected) => selected.join(", ")} // Display selected skills as a comma-separated string
                        >
                          <MenuItem value="JavaScript">JavaScript</MenuItem>
                          <MenuItem value="React">React</MenuItem>
                          <MenuItem value="Node.js">Node.js</MenuItem>
                          <MenuItem value="Python">Python</MenuItem>
                          <MenuItem value="HTML">HTML</MenuItem>
                          <MenuItem value="CSS">CSS</MenuItem>
                          <MenuItem value="Mongodb">Mongodb</MenuItem>
                          <MenuItem value="SQL">SQL</MenuItem>
                          <MenuItem value="Business-Marketing">
                            Business Marketing
                          </MenuItem>
                          <MenuItem value="Graphic-Designer">
                            Graphic Designer
                          </MenuItem>
                          <MenuItem value="Video-Editor">Video Editor</MenuItem>
                          <MenuItem value="Meta-Ads">Meta Ads</MenuItem>
                          <MenuItem value="Google-Ads">Google Ads</MenuItem>
                          <MenuItem value="Performence-Marketing">
                            Performence Marketing
                          </MenuItem>
                          <MenuItem value="SEO">SEO</MenuItem>
                          <MenuItem value="SMO">SMO</MenuItem>
                          <MenuItem value="Copy-Writing">Copy Writing</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Experience"
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Enter required experience in years"
                        value={experience}
                        onChange={(e) => {
                          setExperience(e.target.value);
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="Intern-type-label">
                          Intern Type
                        </InputLabel>
                        <Select
                          labelId="Intern-type-label"
                          id="Intern-type-select"
                          value={Interntype}
                          onChange={(e) => {
                            setInterntype(e.target.value);
                          }}
                          label="Intern Type"
                        >
                          <MenuItem value="Dream-Team">Dream Team</MenuItem>
                          <MenuItem value="Avengers-Team">
                            Avengers Team
                          </MenuItem>
                          <MenuItem value="Squad-Team">Squad Team</MenuItem>
                          <MenuItem value="Elite-Team">Elite Team</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Designation"
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Enter job designation"
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Location"
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Enter job location"
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="skills-label">Skills</InputLabel>
                        <Select
                          labelId="skills-label"
                          id="skills-select"
                          multiple
                          value={skills} // Bind the selected skills state here
                          onChange={(e) => setSkills(e.target.value)} // Handle the change
                          label="Skills"
                          renderValue={(selected) => selected.join(", ")} // Display selected skills as a comma-separated string
                        >
                          <MenuItem value="JavaScript">JavaScript</MenuItem>
                          <MenuItem value="React">React</MenuItem>
                          <MenuItem value="Node.js">Node.js</MenuItem>
                          <MenuItem value="Python">Python</MenuItem>
                          <MenuItem value="HTML">HTML</MenuItem>
                          <MenuItem value="CSS">CSS</MenuItem>
                          <MenuItem value="Mongodb">Mongodb</MenuItem>
                          <MenuItem value="SQL">SQL</MenuItem>
                          <MenuItem value="Business-Marketing">
                            Business Marketing
                          </MenuItem>
                          <MenuItem value="Graphic-Designer">
                            Graphic Designer
                          </MenuItem>
                          <MenuItem value="Video-Editor">Video Editor</MenuItem>
                          <MenuItem value="Meta-Ads">Meta Ads</MenuItem>
                          <MenuItem value="Google-Ads">Google Ads</MenuItem>
                          <MenuItem value="Performence-Marketing">
                            Performence Marketing
                          </MenuItem>
                          <MenuItem value="SEO">SEO</MenuItem>
                          <MenuItem value="SMO">SMO</MenuItem>
                          <MenuItem value="Copy-Writing">Copy Writing</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="Price-label">Price</InputLabel>
                        <Select
                          labelId="Price-label"
                          id="Price-select"
                          value={Price}
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                          label="Intern Type"
                        >
                          <MenuItem value="Free">Free</MenuItem>
                          <MenuItem value="Paid">Paid</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Days "
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Enter Internship Days"
                        value={days}
                        onChange={(e) => {
                          setDays(e.target.value);
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "16px",
                  marginTop: 3,
                }}
              >
                <Link to="/job" style={{ direction: "none" }}>
                  <Button
                    type="button"
                    variant="outlined"
                    sx={{
                      fontWeight: "bold",
                      width: "140px",
                      borderColor: "#E03012",
                      color: "#E03012",
                      "&:hover": {
                        backgroundColor: "#E03012",
                        color: "#fff",
                        borderColor: "#E03012",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#333333",
                    fontWeight: "bold",
                    width: "140px",
                    "&:hover": {
                      backgroundColor: "#555555",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <LodarCont />
    </Grid>
  );
}
