import React, { Component } from "react";
import { connect } from "react-redux";
import Addjobs from "../Components/addjobs";

import { addJobs } from "../action";
export class Controller extends Component {
  render() {
    return <Addjobs {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    addJobs: (
      token,
      user_id,
      jobType,
      designation,
      location,
      skills,
      Interntype,
      Price,
      experience,
      days
    ) => {
      dispatch(
        addJobs(
          token,
          user_id,
          jobType,
          designation,
          location,
          skills,
          Interntype,
          Price,
          experience,
          days
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
