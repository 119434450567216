import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  ListItemText,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import WorkIcon from "@mui/icons-material/Work";
import ContactsIcon from "@mui/icons-material/Contacts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ApprovalIcon from "@mui/icons-material/Approval";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import logoImage from "../../Pages/images/j_nine_infotech_logo.jpg";
import { Link as RouterLink } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

const Navbar = (props) => {
  const [anchorEl, setIsAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [careerMenuOpen, setCareerMenuOpen] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleMenuOpen = (event) => {
    setIsAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setIsAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setOpenDrawer(open);
  };

  const toggleCareerMenu = () => {
    setCareerMenuOpen(!careerMenuOpen);
  };

  const DrawerList = (
    <Box
      sx={{
        width: 250,
        backgroundColor: "black",
        height: "100%",
        paddingTop: "16px",
      }}
      role="presentation"
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/admin"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ backgroundColor: "gray" }} />
        {/* <ListItem disablePadding>
          <ListItemButton onClick={toggleCareerMenu}>
            <ListItemIcon>
              <AutoStoriesIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Career" sx={{ color: "white" }} />
            {careerMenuOpen ? (
              <ExpandLessIcon sx={{ color: "white" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "white" }} />
            )}
          </ListItemButton>
        </ListItem> */}
        {/* {careerMenuOpen && (
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton
                component={RouterLink}
                to="/job"
                onClick={toggleDrawer(false)}
              >
                <ListItemIcon>
                  <WorkOutlineIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Job" sx={{ color: "white" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={RouterLink}
                to="/internship"
                onClick={toggleDrawer(false)}
              >
                <ListItemIcon>
                  <WorkOutlineIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Internship" sx={{ color: "white" }} />
              </ListItemButton>
            </ListItem>
          </List>
        )} */}
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/job"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <WorkIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Job Post" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ backgroundColor: "gray" }} />
        {/* <Divider sx={{ backgroundColor: "gray" }} /> */}
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/applied"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <ApprovalIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Applied" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ backgroundColor: "gray" }} />
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/blog-category"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <FolderCopyIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Blog Category" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ backgroundColor: "gray" }} />
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/blogpost"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <BorderColorIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Blog" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ backgroundColor: "gray" }} />
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to="/contact-details"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <ContactsIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Contact" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        height: "60px",
        zIndex: 1201,
      }}
    >
      <Toolbar
        sx={{
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {/* Drawer Toggle Button */}
          <IconButton
            sx={{ display: { xs: "flex", md: "flex" }, color: "white" }}
            onClick={toggleDrawer(!openDrawer)}
          >
            {openDrawer ? <CloseIcon sx={{ color: "white" }} /> : <MenuIcon />}
          </IconButton>
          {/* Logo Section */}
          <Box
            component={RouterLink}
            to="/admin"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logoImage}
              alt="mg"
              style={{
                width: "60px",
                height: "auto",
                marginRight: "8px",
              }}
            />
          </Box>
        </Box>

        {/* Avatar and Menu Section */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            alt="User Avatar"
            src={props.login?.profile_pic || props.login?.name}
            sx={{ width: 40, height: 40, cursor: "pointer" }}
            onClick={handleMenuOpen}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              component={RouterLink}
              to="/profile"
              onClick={handleMenuClose}
            >
              <AccountCircleIcon sx={{ marginRight: 1 }} />
              Profile
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/admin"
              onClick={(e) => {
                props.onLogout();
                handleMenuClose();
              }}
            >
              <LogoutIcon sx={{ marginRight: 1 }} />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            marginTop: "60px",
            backgroundColor: "black",
            color: "white",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
