import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from '../bannerimg/Website-design.png'
import image2 from '../bannerimg/2150038840.jpg';
import image3 from '../bannerimg/4344113_2214999.jpg';
import image4 from '../bannerimg/njuqY8bVEAK48uSGWiXxPi.png';
import image5 from '../../images/image5.jpg';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles"; 
import { useLocation } from "react-router-dom";

const ResponsiveCarousel = ({ autoSlide = true, autoSlideInterval = 2000 }) => {
  const theme = useTheme(); 
  const [currentIndex, setCurrentIndex] = useState(0);
  const { pathname } = useLocation();

  const carData = [
    { id: 1, image: image1 },
    { id: 2, image: image2 },
    { id: 3, image: image3 },
    { id: 4, image: image4 },
    { id: 5, image: image5 },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + carData.length) % carData.length;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % carData.length;
    setCurrentIndex(newIndex);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Box sx={{ overflow: 'hidden', position: 'relative' }}>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay={autoSlide}
        interval={autoSlideInterval}
        infiniteLoop={true}
        transitionTime={500}
        selectedItem={currentIndex}
        onChange={(index) => setCurrentIndex(index)}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <Button
              onClick={handlePrev}
              sx={{
                position: "absolute",
                left: "40px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 10,
                color: "#fff",
                background: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#ffeb3b",
                },
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: 30, color: "#fff" }} />
            </Button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <Button
              onClick={handleNext}
              sx={{
                position: "absolute",
                right: "40px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 10,
                color: "#fff",
                background: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#ffeb3b",
                },
              }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: 30, color: "#fff" }} />
            </Button>
          )
        }
      >
        {carData.map((car) => (
          <Box
            key={car.id}
            sx={{
              width: "100%",
              height: { md: "100vh", sm: "60vh", xs: "30vh" },
              backgroundImage: `url(${car.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        ))}
      </Carousel>

      {/* Dot Indicators Positioned on Top of Image */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: "5%",
          width: "100%",
          gap: "8px",
          zIndex: 5,
        }}
      >
        {carData.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: index === currentIndex ? "red" : "#fff",
              opacity: index === currentIndex ? 1 : 0.5,
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ResponsiveCarousel;
