import {
  VIEWJOBSBYID,
  ALLJOBS,
  ALLCONTACTUS,
  ALLBOLOGS,
  VIEWALLAPPLICATIONS,
  VIEWALLCATEGORY,
  VIEWALLBLOG,
  VIEWALLCATEGORYS,
} from "./constant";
import UNIVERSAL from "../../src/Config/Config";
import { setLoader, unsetLoader } from "../Common/loader/action";
import { set_snack_bar } from "../Common/snackbar/action";
import "firebase/storage";

// Check if the user is authenticated
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

//----------------------------------------------ContactUs---------------------------------------------------------//

export function AddContactForm(
  firstName,
  lastName,
  mobile,
  email,
  queryType,
  querySubject,
  query,
  agreedToPolicy
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        mobile,
        email,
        queryType,
        querySubject,
        query,
        agreedToPolicy,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch();
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch();
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllContactUs(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  selectedDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchTerm: searchInput,
        page: parseInt(page) + 1,
        pageSize,
        selectedDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLCONTACTUS,
            payload: responseJson.data.data,
            count: responseJson.data.contactUsDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLCONTACTUS, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteContact(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateContactStatus(
  token,
  user_id,
  id,
  status,
  page,
  pageSize
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "contactus/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllContactUs(token, user_id, "", page, pageSize, "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//--------------------------------------------Blogs------------------------------------//
export function SaveContent(token, user_id, htmlContent) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "blog/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        htmlContent,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(viewAllContent());
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(viewAllContent());
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllContent() {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "blog/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // token,
      },
      body: JSON.stringify({
        // searchTerm: searchInput,
        // page: parseInt(page) + 1,
        // pageSize,
        // selectedDate,
        // selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLBOLOGS,
            payload: responseJson.data.data,
            count: responseJson.data.contactUsDetail,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLBOLOGS, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//--------------------------------------------------add Jobs-----------------------------------------------------//
export function addJobs(
  token,
  user_id,
  jobType,
  designation,
  location,
  skills,
  Interntype,
  Price,
  experience,
  days
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobPost/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        jobType,
        designation,
        location,
        skills,
        Interntype,
        Price,
        experience,
        days,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(viewAllJobs(token, user_id, "", 0, 10, "", "", ""));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(viewAllJobs(token, user_id, "", 0, 10, "", "", ""));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAllJobs(
  token,
  user_id,
  searchInput,
  page,
  rowsPerPage,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobPost/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        searchInput,
        page: page + 1,
        pageSize: rowsPerPage,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: ALLJOBS,
            payload: responseJson.data.data,
            count: responseJson.data.totalJobPosts,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: ALLJOBS, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function deleteJobs(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobPost/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(viewAllJobs(token, user_id, "", page, pageSize, "", "", ""));
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(viewAllJobs(token, user_id, "", page, pageSize, "", "", ""));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function updateJobsStatus(token, user_id, id, status, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobPost/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(viewAllJobs(token, user_id, "", page, pageSize, "", "", ""));
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(viewAllJobs(token, user_id, "", page, pageSize, "", "", ""));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewJobsById(token, user_id, job_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobPost/viewJobPostById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        id: job_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWJOBSBYID,
            payload: responseJson.data.result,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWJOBSBYID, payload: {} });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function editJobs(
  token,
  user_id,
  job_id,
  jobType,
  designation,
  location,
  skills,
  Interntype,
  Price,
  experience,
  days
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobPost/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id: job_id,
        jobType,
        designation,
        location,
        skills,
        Interntype,
        Price,
        experience,
        days,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(viewAllJobs(token, user_id, "", 0, 10, "", "", ""));
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(viewAllJobs(token, user_id, "", 0, 10, "", "", ""));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//----------------------------------------------------------------Application view-----------------------------------------//

export function viewAllApplication(
  token,
  user_id,
  searchInput,
  page,
  rowsPerPage,
  selectedDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobApply/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        searchInput,
        page: page + 1,
        pageSize: rowsPerPage,
        selectedDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWALLAPPLICATIONS,
            payload: responseJson.data.data,
            count: responseJson.data.totalApplyJobs,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWALLAPPLICATIONS, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function updateApplicationtStatus(
  token,
  user_id,
  id,
  status,
  page,
  rowsPerPage
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobApply/updateStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllApplication(token, user_id, "", page, rowsPerPage, "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllApplication(token, user_id, "", page, rowsPerPage, "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function deleteApplications(token, user_id, id, page, rowsPerPage) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobApply/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllApplication(token, user_id, "", page, rowsPerPage, "", "")
          );
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllApplication(token, user_id, "", page, rowsPerPage, "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//--------------------------------------------------Blog category---------------------------------------------------//
export function addCategory(token, user_id, category, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "category/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        category,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllCategory(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllCategory(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAllCategory(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "category/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        searchInput,
        page: page + 1,
        pageSize: pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWALLCATEGORY,
            payload: responseJson.data.data,
            count: responseJson.data.totalCategorys,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWALLCATEGORY, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function EditCategory(token, user_id, id, category, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "category/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        id,
        category,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllCategory(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllCategory(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function deleteCategory(token, user_id, id, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "category/delete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(
            viewAllCategory(token, user_id, "", page, pageSize, "", "", "")
          );
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(
            viewAllCategory(token, user_id, "", page, pageSize, "", "", "")
          );
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

// --------------------------------------Add Blog-------------------------------------------------------------//

export function SaveBlog(
  token,
  user_id,
  category,
  mediaFiles, // Accept both images and videos in a single array
  heading,
  writtenBy,
  htmlContent
) {
  return (dispatch) => {
    dispatch(setLoader(true)); // Show loader

    if (!mediaFiles || mediaFiles.length === 0) {
      dispatch(
        set_snack_bar(true, "Please upload at least one valid media file.")
      );
      dispatch(setLoader(false));
      return;
    }

    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    const validVideoTypes = ["video/mp4", "video/avi", "video/mkv"];

    // Single array to store both image and video URLs
    let mediaURLs = [];

    // Iterate over the media files and upload each one
    mediaFiles.forEach((file) => {
      let fileType = file.type;
      let fileCategory = ""; // Define whether it's an image or video

      // Check for valid file type and determine category
      if (validImageTypes.includes(fileType)) {
        fileCategory = "images";
      } else if (validVideoTypes.includes(fileType)) {
        fileCategory = "videos";
      } else {
        dispatch(
          set_snack_bar(
            true,
            "Invalid file type. Only images and videos are allowed."
          )
        );
        dispatch(setLoader(false));
        return;
      }

      // Generate a unique filename for the file
      const fileName = `${file.name}-${Math.random()
        .toString(36)
        .substr(2, 9)}.${file.name.split(".").pop()}`;
      const storageRef = ref(
        getStorage(),
        `/uploads/${fileCategory}/${fileName}`
      );

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`${fileCategory} upload is ${progress}% done`);
        },
        (error) => {
          console.error(`${fileCategory} upload failed:`, error);
          dispatch(
            set_snack_bar(true, `${fileCategory} could not be uploaded.`)
          );
          dispatch(setLoader(false));
        },
        () => {
          // Handle successful upload
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            mediaURLs.push(downloadURL); // Collect the media URL into the mediaURLs array

            // If all uploads are complete, dispatch the blog post action
            if (mediaURLs.length === mediaFiles.length) {
              dispatch(
                addBlogs(
                  token,
                  user_id,
                  category,
                  mediaURLs,
                  heading,
                  writtenBy,
                  htmlContent
                  // Pass the combined media URLs array
                )
              );
              dispatch(set_snack_bar(true, "Media uploaded successfully."));
              dispatch(setLoader(false));
            }
          });
        }
      );
    });
  };
}

export function addBlogs(
  token,
  user_id,
  category,
  mediaURLs,
  heading,
  writtenBy,
  htmlContent
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "blog/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        category,
        mediaURLs,
        heading,
        writtenBy,
        htmlContent,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch(viewAllBlog(token, user_id, "", 0, 10, "", "", ""));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch(viewAllBlog(token, user_id, "", 0, 10, "", "", ""));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllBlog(
  token,
  user_id,
  searchInput,
  page,
  pageSize,
  startDate,
  endDate,
  selectedStatus
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "blog/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        searchInput,
        page: page + 1,
        pageSize: pageSize,
        startDate,
        endDate,
        selectedStatus,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWALLBLOG,
            payload: responseJson.data.data,
            count: responseJson.data.totalBlogs            ,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWALLBLOG, payload: [], count: 0 });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function viewAllCategoryWithOutPgn(token) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "category/viewCategory", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWALLCATEGORYS,
            payload: responseJson.data,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWALLCATEGORYS, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
