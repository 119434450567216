import {
    ALLBOLOG,
    ALLBLOGBYDATES,
    VIEWBLOGBYID
  } from "./constant";
  import UNIVERSAL from "../../Config/Config";
  import { setLoader, unsetLoader } from "../../Common/loader/action";
  import { set_snack_bar } from "../../Common/snackbar/action";
  import "firebase/storage";
  
  // Check if the user is authenticated
  import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
  } from "firebase/auth";
  import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
  } from "firebase/storage";
  
  
  //--------------------------------------------Blogs------------------------------------//
  
  export function viewBlogsByCategory(category) {
    return (dispatch) => {
      dispatch(setLoader());
      return fetch(UNIVERSAL.BASEURL + "blog/viewBlogByCategory", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         
        },
        body: JSON.stringify({
          category,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (
            responseJson.statusCode === 200 ||
            responseJson.statusCode === 201
          ) {
            dispatch({
              type: ALLBOLOG,
              payload: responseJson.data.result,
              count: responseJson.data.contactUsDetail,
            });
            dispatch(unsetLoader());
            // dispatch(set_snack_bar(responseJson.status, responseJson.message));
          } else if (
            responseJson.statusCode === 401 ||
            responseJson.statusCode === 404
          ) {
            dispatch({ type: ALLBOLOG, payload: [], count: 0 });
            console.log("data Not Found");
            dispatch(unsetLoader());
          }
        })
        .catch((error) => {
          // dispatch(unsetLoader());
          // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
          console.error(error);
        });
    };
  }
  export function viewBlogsByDates() {
    return (dispatch) => {
      dispatch(setLoader());
      return fetch(UNIVERSAL.BASEURL + "blog/viewBlogsByDates", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         
        },
       
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (
            responseJson.statusCode === 200 ||
            responseJson.statusCode === 201
          ) {
            dispatch({
              type: ALLBLOGBYDATES,
              payload: responseJson.data.result,
              count: responseJson.data.contactUsDetail,
            });
            dispatch(unsetLoader());
            // dispatch(set_snack_bar(responseJson.status, responseJson.message));
          } else if (
            responseJson.statusCode === 401 ||
            responseJson.statusCode === 404
          ) {
            dispatch({ type: ALLBLOGBYDATES, payload: [], count: 0 });
            console.log("data Not Found");
            dispatch(unsetLoader());
          }
        })
        .catch((error) => {
          // dispatch(unsetLoader());
          // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
          console.error(error);
        });
    };
  }
  
  
  export function viewBlogById() {
    return (dispatch) => {
      dispatch(setLoader());
      return fetch(UNIVERSAL.BASEURL + "blog/viewBlogById", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         
        },
       
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (
            responseJson.statusCode === 200 ||
            responseJson.statusCode === 201
          ) {
            dispatch({
              type: VIEWBLOGBYID,
              payload: responseJson.data.result,
            });
            dispatch(unsetLoader());
            // dispatch(set_snack_bar(responseJson.status, responseJson.message));
          } else if (
            responseJson.statusCode === 401 ||
            responseJson.statusCode === 404
          ) {
            dispatch({ type: VIEWBLOGBYID, payload: {}, });
            console.log("data Not Found");
            dispatch(unsetLoader());
          }
        })
        .catch((error) => {
          // dispatch(unsetLoader());
          // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
          console.error(error);
        });
    };
  }