import { VIEWALLJOBS } from "./constant";
const initial_state = {
  all_jobs: [],
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case VIEWALLJOBS:
      return (state = {
        ...state,
        all_jobs: action.payload,
      });
    default:
      return state;
  }
}
