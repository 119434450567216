import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
  DialogContentText,
  Switch,
} from "@mui/material";
import { Eye, EyeOff } from "lucide-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./login.css";
import logoImage from "../../Pages/images/j_nine_infotech_logo.jpg";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: true,
      forget: false,
      showPassword: false,
      email: "",
      password: "",
      isDarkMode: true,
    };
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  toggleTheme = () => {
    this.setState((prevState) => ({ isDarkMode: !prevState.isDarkMode }));
  };

  render() {
    const { showPassword, forget, email, password, isDarkMode } = this.state;

    const theme = createTheme({
      palette: {
        mode: isDarkMode ? "dark" : "light",
        primary: {
          main: "#ff6347",
        },
        background: {
          default: isDarkMode ? "#121212" : "#f5f5f5",
          paper: isDarkMode ? "#1e1e1e" : "#ffffff",
        },
        text: {
          primary: isDarkMode ? "#ffffff" : "#000000",
          secondary: isDarkMode ? "#ccc" : "#555",
        },
      },
      typography: {
        fontFamily: "Roboto, sans-serif",
      },
    });
    const { forgotpasswords } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            minHeight: "80vh",
            backgroundColor: theme.palette.background.default,
            padding: "20px",
          }}
        >
          <Dialog
            open={this.state.form}
            maxWidth="md"
            fullWidth
            // maxWidth="xs" // Set to 'xs' for an extra small dialog
            // fullWidth={false} // Prevent it from taking full width

            PaperProps={{
              style: {
                width: "550px",
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderRadius: "15px",
                padding: "30px",
              },
            }}
          >
            <DialogContent>
              <Grid container spacing={3}>
                {/* Theme Toggle */}
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Typography
                    variant="body2"
                    style={{ display: "inline", marginRight: "0px" }}
                  >
                    {isDarkMode ? "Dark Mode" : "Light Mode"}
                  </Typography>
                  <Switch checked={isDarkMode} onChange={this.toggleTheme} />
                </Grid>
                {/* Logo */}
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginBottom: "70px" }}
                >
                  <img
                    src={logoImage}
                    alt="Logo"
                    style={{ width: "120px", height: "auto" }}
                  />
                </Grid>

                {!forget && (
                  <>
                    {/* Login Section */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ margin: "auto", marginTop: "-80px" }}
                    >
                      <Typography variant="h4" textAlign="center">
                        Login
                      </Typography>
                      <Grid container mt={4}>
                        <Grid item xs={12}>
                          <Typography
                            className="text"
                            style={{ marginBottom: "8px" }}
                          >
                            Email Id{" "}
                            <span style={{ color: "red", fontSize: "20px" }}>
                              *
                            </span>
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={2}>
                        <Grid item xs={12}>
                          <Typography
                            className="text"
                            style={{ marginBottom: "8px" }}
                          >
                            Password
                            <span style={{ color: "red", fontSize: "20px" }}>
                              *
                            </span>
                          </Typography>
                          <FormControl fullWidth>
                            <OutlinedInput
                              type={showPassword ? "text" : "password"}
                              size="small"
                              value={password}
                              onChange={(e) =>
                                this.setState({ password: e.target.value })
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <EyeOff /> : <Eye />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <Typography
                            mt={1}
                            fontSize={12}
                            style={{
                              cursor: "pointer",
                              color: theme.palette.primary.main,
                            }}
                            onClick={() => this.setState({ forget: true })}
                          >
                            Forgot password?
                          </Typography>
                        </Grid>
                      </Grid>
                      <center>
                        <Button
                          variant="contained"
                          sx={{
                            marginTop: 5,
                            padding: "10px 20px",
                          }}
                          onClick={() => {
                            this.props.loginFetch(email, password);
                            this.setState({ email: "", password: "" });
                          }}
                        >
                          Login
                        </Button>
                      </center>
                    </Grid>
                  </>
                )}

                {forget && (
                  <>
                    {/* Forgot Password Section */}
                    <Grid item xs={12} sm={6} style={{ margin: "auto" }}>
                      <Typography variant="h4" textAlign="center">
                        Forgot Password
                      </Typography>
                      <DialogContentText
                        style={{ textAlign: "center", marginTop: "10px" }}
                      >
                        Please provide your registered email for password reset.
                      </DialogContentText>
                      <Grid container mt={4}>
                        <Grid item xs={12}>
                          <Typography
                            className="text"
                            style={{ marginBottom: "8px" }}
                          >
                            Email Id
                            <span style={{ color: "red", fontSize: "20px" }}>
                              *
                            </span>
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </Grid>
                      </Grid>
                      <center>
                        <Button
                          variant="contained"
                          sx={{
                            marginTop: 5,
                            padding: "10px 20px",
                          }}
                          onClick={() => {
                            forgotpasswords(email);
                            this.setState({ forget: false});
                          }}
                        >
                          Submit
                        </Button>
                      </center>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </ThemeProvider>
    );
  }
}
