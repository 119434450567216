import React, { Component } from "react";
import { connect } from "react-redux";
import Blog from "../Components/Blog";

import { viewAllCategoryWithOutPgn } from "../../../Client/action";
import { viewBlogsByCategory,viewBlogsByDates } from "../action";
export class Controller extends Component {
  render() {
    return <Blog {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
    blog: store.blog,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllCategoryWithOutPgn: (token) => {
      dispatch(viewAllCategoryWithOutPgn(token));
    },
    viewBlogsByCategory: (category) => {
      dispatch(viewBlogsByCategory(category));
    },
    viewBlogsByDates: () => {
        dispatch(viewBlogsByDates());
      },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
