import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Dialog,
  DialogContent,
  useMediaQuery,
  Fade,
  CardActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../images/career.jpg";
import DownloadIcon from "@mui/icons-material/Download";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import _ from "lodash";
const AnimatedSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.2, // Triggers animation when 20% of the component is visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, delay }}
    >
      {children}
    </motion.div>
  );
};
const CareerPage = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [internopen, setinternOpen] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [annualSal, setAnnualSal] = useState("");
  const [expectationsSal, setExpectationsSal] = useState("");
  const [resume, setResume] = useState(null);
  const [pcompany, setPcompany] = useState("");
  const [jobId, setJobId] = useState(null);
  const [internName, setInternName] = useState("");
  const [internMobile, setInternMobile] = useState("");
  const [internEmail, setInternEmail] = useState("");
  const [internQualification, setInternQualification] = useState("");
  const [internResume, setInternResume] = useState(null);

  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const handleRegister = () => {
    setinternOpen(true);
    setJobId(123);
  };

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggleIcons = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    props.viewAllJobPost();
    console.log(props.career.all_jobs);
  }, []);

  const teams = [
    { name: "Dream Team", period: "3 Weeks" },
    { name: "Avengers Team", period: "4 Weeks" },
    { name: "Squad Team", period: "6 Weeks" },
    { name: "Elite Team", period: "2 Month" },
  ];

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleResumeUpload = (e) => {
    const file = e.target.files[0];
    setResume(file);
    alert(`Resume uploaded: ${file.name}`);
  };
  const handleInternResumeUpload = (e) => {
    const file = e.target.files[0];
    setInternResume(file);
    alert(`Resume uploaded: ${file.name}`);
  };
  const jobData = [
    {
      id: "Full-Time",
      color: "#03a9f4", // Blue
    },
    {
      id: "Part-Time",
      color: "#BE69BB", // Pink
    },
    {
      id: "Internship",
      color: "#03a9f4", // Green
    },
    {
      id: "Remote",
      color: "#9c27b0", // Purple
    },
  ];

  const debouncedCheckEmail = useCallback(
    _.debounce(async (email) => {
      try {
        const response = await fetch(
          `https://jninebackend-520354961698.asia-south1.run.app/jobApply/check-Email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to check Email");
        }

        const result = await response.json();

        if (result.data.isUnique) {
          setIsEmailError(false);
          setEmailErrorText("");
        } else {
          setIsEmailError(true);
          setEmailErrorText("Email is already Used.");
        }
      } catch (error) {
        console.error("Error checking Email Id:", error);
        setIsEmailError(true);
        setEmailErrorText("Error checking Email Id .");
      }
    }, 300),
    []
  );

  // Handle username change
  const handleEmailChange = (email) => {
    const trimmedEmail = email.trim();

    setEmail(trimmedEmail);
    setInternEmail(trimmedEmail);

    if (trimmedEmail === "") {
      setIsEmailError(true);
      setEmailErrorText("Email is required.");
      return;
    }

    // Call debounced function to check uniqueness
    debouncedCheckEmail(trimmedEmail);
  };
  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          height: "100vh",
          overflow: "hidden",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginTop: "58px",
        }}
      >
        <AnimatedSection>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "100vh",
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                mb: 2,
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Bring Your Dreams To Life
            </Typography>

            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleScroll("career")}
                sx={{
                  p: 3,
                  fontFamily: "Montserrat, Sans-serif",
                  backgroundColor: "#fff",
                  color: "#000",
                  "&:hover": { backgroundColor: "#ccc" },
                }}
              >
                Explore Opening
              </Button>
              <Button
                variant="contained"
                onClick={() => handleScroll("internship")}
                sx={{
                  backgroundColor: "#555",
                  color: "#fff",
                  fontFamily: "Montserrat, Sans-serif",
                  p: 3,
                  "&:hover": { backgroundColor: "#333" },
                }}
              >
                Explore Internship
              </Button>
            </Box>
          </Box>
        </AnimatedSection>
      </Box>

      {/* Career Section */}

      <Box id="career" sx={{ padding: 4, backgroundColor: "#0F1214" }}>
        <AnimatedSection>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              mb: 0.4,
              fontFamily: "Montserrat, Sans-serif",
              color: "#fff",
            }}
          >
            Looking For Career
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              mb: 3,
              fontFamily: "Montserrat, Sans-serif",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Check out our current openings
          </Typography>
          <Grid container spacing={3}>
            <Grid container spacing={3}>
              {props.career.all_jobs?.filter(
                (job) => job.jobType !== "Internship"
              ).length > 0 ? (
                props.career.all_jobs
                  ?.filter((job) => job.jobType !== "Internship")
                  ?.map((job, index) => {
                    // Find matching color for the job
                    const jobBackgroundData = jobData.find(
                      (data) => data.id === job.jobType // Ensure this matches your data structure
                    );

                    const backgroundColor = jobBackgroundData?.color || "#000"; // Default fallback color

                    return (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {/* Title Section */}
                          <Box
                            sx={{
                              backgroundColor: backgroundColor,
                              padding: 2,
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "4px 4px 0 0",
                              minHeight: "64px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "Montserrat, Sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              {job.designation}
                            </Typography>
                          </Box>

                          {/* Description Section */}
                          <CardContent sx={{ flex: 1 }}>
                            <Typography variant="body1" sx={{ mb: 1 }}>
                              Job Type: {job.jobType}
                            </Typography>
                            {job.experience !== "0" && job.experience !== 0 ? (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: "Montserrat, Sans-serif",
                                  mb: 1,
                                  color: "#757575",
                                }}
                              >
                                Experience: {job.experience} years
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: "Montserrat, Sans-serif",
                                  mb: 1,
                                  color: "#757575",
                                }}
                              >
                                Freshers
                              </Typography>
                            )}

                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: "Montserrat, Sans-serif",
                                color: "#757575",
                              }}
                            >
                              Skills Required: {job.skills.join(", ")}
                            </Typography>

                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: "Montserrat, Sans-serif",
                                color: "#757575",
                              }}
                            >
                              Location: {job.location}
                            </Typography>
                          </CardContent>

                          {/* Buttons Section */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: 2,
                              padding: 2,
                              borderTop: "1px solid #eee",
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#333",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#444" },
                              }}
                              onClick={() => navigate("/contact")}
                            >
                              Contact Us
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: backgroundColor,
                                "&:hover": { backgroundColor: backgroundColor },
                              }}
                              onClick={() => {
                                setOpen(true);
                                setJobId(job._id); // Dynamically setting job ID
                                setName("");
                                setEmail("");
                                setMobile("");
                                setQualification("");
                                setExperience("");
                                setAnnualSal("");
                                setExpectationsSal("");
                                setPcompany("");
                                setResume(null);
                              }}
                            >
                              Apply
                            </Button>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })
              ) : (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                      textAlign: "center",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "Montserrat, Sans-serif",
                        color: "#757575",
                      }}
                    >
                      No vacancies available at the moment.
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </AnimatedSection>
      </Box>

      {/* Dialog for Apply */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            borderRadius: 2,
            padding: 4,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              mb: 2,
              fontWeight: "bold",
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Apply for Vacancy !!
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              alert("Application Submitted Successfully!");
              props.ApplyJob(
                jobId,
                name,
                mobile,
                email,
                qualification,
                experience,
                annualSal,
                expectationsSal,
                pcompany,
                "job",
                resume
              );
              setName("");
              setEmail("");
              setMobile("");
              setQualification("");
              setExperience("");
              setAnnualSal("");
              setExpectationsSal("");
              setPcompany("");
              setResume(null);
              setIsEmailError(false);
              setEmailErrorText("");
              setOpen(false);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Name"
                  type="text"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 1,
                    "& input": { fontSize: "14px" },
                  }}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Mobile"
                  type="number"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 1,
                    "& input": { fontSize: "14px" },
                  }}
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  type="email"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 1,
                    "& input": { fontSize: "14px" },
                  }}
                  value={email}
                  // onChange={(e) => {
                  //   setEmail(e.target.value);
                  // }}
                  onChange={(e) => handleEmailChange(e.target.value)}
                  error={isEmailError}
                  helperText={isEmailError ? emailErrorText : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Present Company"
                  type="text"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 1,
                    "& input": { fontSize: "14px" },
                  }}
                  value={pcompany}
                  onChange={(e) => {
                    setPcompany(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Latest Qualification"
                  type="text"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 1,
                    "& input": { fontSize: "14px" },
                  }}
                  value={qualification}
                  onChange={(e) => {
                    setQualification(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Experience"
                  type="number"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 1,
                    "& input": { fontSize: "14px" },
                  }}
                  value={experience}
                  onChange={(e) => {
                    setExperience(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Current Annual Salary"
                  type="number"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 1,
                    "& input": { fontSize: "14px" },
                  }}
                  value={annualSal}
                  onChange={(e) => {
                    setAnnualSal(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Your Expectations"
                  type="number"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 1,
                    "& input": { fontSize: "14px" },
                  }}
                  value={expectationsSal}
                  onChange={(e) => {
                    setExpectationsSal(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{
                    backgroundColor: "#fff",
                    color: "#000",
                    fontWeight: 800,
                    "&:hover": { backgroundColor: "#fff", color: "#000" },
                  }}
                >
                  <DownloadIcon />
                  Please upload your Resume
                  <input
                    type="file"
                    accept="application/pdf"
                    hidden
                    onChange={handleResumeUpload}
                  />
                </Button>
                {resume && (
                  <Typography variant="body2" sx={{ mt: 1, color: "#fff" }}>
                    {`Uploaded: ${resume.name}`}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  width: "150px",
                  mt: 3,
                  backgroundColor: "#03a9f4",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#03a9f4" },
                }}
              >
                APPLY NOW
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      {/* Intern */}

      <Box id="internship" sx={{ p: 4, backgroundColor: "#0F1214" }}>
        <AnimatedSection>
          <Typography
            variant="h4"
            align="center"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              mb: 3,
              fontFamily: "Montserrat, Sans-serif",
              color: "#fff",
            }}
          >
            Apply Internship
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {props.career.all_jobs?.filter(
              (job) => job.jobType === "Internship"
            )?.length > 0 ? (
              props.career.all_jobs
                ?.filter((job) => job.jobType === "Internship")
                ?.map((job, index) => {
                  // Find matching color for the job
                  const jobBackgroundData = jobData.find(
                    (data) => data.id === job.jobType // Ensure this matches your data structure
                  );

                  const backgroundColor = jobBackgroundData?.color || "#000"; // Default fallback color

                  return (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/* Title Section */}
                        <Box
                          sx={{
                            backgroundColor: backgroundColor,
                            padding: 2,
                            color: "#fff",
                            textAlign: "center",
                            borderRadius: "4px 4px 0 0",
                            minHeight: "64px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontFamily: "Montserrat, Sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            {job.Interntype}
                          </Typography>
                        </Box>

                        {/* Description Section */}
                        <CardContent sx={{ flex: 1 }}>
                          <Typography variant="body1" sx={{ mb: 1 }}>
                            Apply Only: {job.designation}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "Montserrat, Sans-serif",
                              color: "#757575",
                            }}
                          >
                            Classes: {job.days} Days
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "Montserrat, Sans-serif",
                              color: "#757575",
                            }}
                          >
                            Provide Skills: {job.skills.join(", ")}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "Montserrat, Sans-serif",
                              color: "#757575",
                            }}
                          >
                            Location: {job.location}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "Montserrat, Sans-serif",
                              color: "#757575",
                            }}
                          >
                            Internship Type: {job.Price}
                          </Typography>
                        </CardContent>

                        {/* Buttons Section */}
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            backgroundColor: backgroundColor,
                            fontWeight: 800,
                            color: "#000",
                            "&:hover": { backgroundColor: backgroundColor },
                          }}
                          onClick={(e) => {
                            setinternOpen(true);
                            setJobId(job._id);
                            setInternName("");
                            setInternMobile("");
                            setInternEmail("");
                            setInternQualification("");
                            setInternResume(null);
                            setIsEmailError(false);
                            setEmailErrorText("");
                          }}
                        >
                          Register for it
                        </Button>
                      </Card>
                    </Grid>
                  );
                })
            ) : (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    textAlign: "center",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Montserrat, Sans-serif",
                      color: "#757575",
                    }}
                  >
                    No internships available at the moment.
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </AnimatedSection>

        {/* Dialog for Form */}
        <Dialog open={internopen} onClose={() => setinternOpen(false)}>
          <DialogContent
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: "#fff",
              borderRadius: 2,
              padding: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{ textAlign: "center", mb: 2, fontWeight: "bold" }}
            >
              Apply for the Internship
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault(); // Prevents the default form submission
                alert("Application Submitted Successfully!");
                props.InternApply(
                  jobId,
                  internName,
                  internMobile,
                  internEmail,
                  internQualification,
                  "Internship",
                  internResume
                );
                setInternName("");
                setInternMobile("");
                setInternEmail("");
                setInternQualification("");
                setInternResume(null);
                setinternOpen(false);
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Name"
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 1,
                      "& input": { fontSize: "14px" },
                    }}
                    required
                    value={internName}
                    onChange={(e) => {
                      setInternName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Mobile"
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 1,
                      "& input": { fontSize: "14px" },
                    }}
                    required
                    value={internMobile}
                    onChange={(e) => {
                      setInternMobile(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Email"
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 1,
                      "& input": { fontSize: "14px" },
                    }}
                    required
                    value={internEmail}
                    // onChange={(e) => {
                    //   setInternEmail(e.target.value);
                    // }}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    error={isEmailError}
                    helperText={isEmailError ? emailErrorText : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    placeholder="Latest Qualification"
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 1,
                      "& input": { fontSize: "14px" },
                    }}
                    required
                    value={internQualification}
                    onChange={(e) => {
                      setInternQualification(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      fontWeight: 800,
                      "&:hover": { backgroundColor: "#fff", color: "#000" },
                    }}
                  >
                    <DownloadIcon />
                    Please upload your Resume
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={handleInternResumeUpload}
                    />
                  </Button>
                  {internResume && (
                    <Typography variant="body2" sx={{ mt: 1, color: "#fff" }}>
                      {`Uploaded: ${internResume.name}`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    backgroundColor: "#03a9f4",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#03a9f4" },
                  }}
                >
                  APPLY NOW
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Box>
      {/* Floating Action Button with Social Icons */}
      <div
        ref={ref}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
            transition: "all 0.3s ease-in-out",
            opacity: isOpen ? 1 : 0,
            transform: isOpen ? "scale(1)" : "scale(0.9)",
            pointerEvents: isOpen ? "all" : "none",
          }}
        >
          {/* WhatsApp */}
          <Tooltip title="WhatsApp" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#25D366", color: "#fff" }}
              onClick={() =>
                window.open("https://wa.me/919583966999", "_blank")
              }
            >
              <WhatsAppIcon />
            </Fab>
          </Tooltip>

          {/* Call */}
          <Tooltip title="Call" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#FF5722", color: "#fff" }}
              onClick={() => window.open("tel:+919583966999", "_self")}
            >
              <CallIcon />
            </Fab>
          </Tooltip>

          {/* Instagram */}
          <Tooltip title="Instagram" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#E1306C", color: "#fff" }}
              onClick={() =>
                window.open("https://www.instagram.com/j9infotech/", "_blank")
              }
            >
              <InstagramIcon />
            </Fab>
          </Tooltip>

          {/* Facebook */}
          <Tooltip title="Facebook" arrow>
            <Fab
              size="small"
              color="primary"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/JNineInfotechh/",
                  "_blank"
                )
              }
            >
              <FacebookIcon />
            </Fab>
          </Tooltip>

          {/* LinkedIn */}
          <Tooltip title="LinkedIn" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#0077B5", color: "#fff" }}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/j-nine-infotech",
                  "_blank"
                )
              }
            >
              <LinkedInIcon />
            </Fab>
          </Tooltip>
        </div>

        {/* Main "+" Button */}
        <Fab
          color="primary"
          onClick={toggleIcons}
          style={{
            backgroundColor: "#3f51b5",
            color: "#fff",
            transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <AddIcon />
        </Fab>
      </div>
    </Box>
  );
};

export default CareerPage;
