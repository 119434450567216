import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Menu,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import image1a from "../../images/blogback.png";
import image1b from "../../images/career.jpg";
import image2a from "../../images/blogs.webp";
import image2b from "../../images/coding.webp";
import image3a from "../../images/byd.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const AnimatedSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.2, // Triggers animation when 20% of the component is visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, delay }}
    >
      {children}
    </motion.div>
  );
};
const blogs = [
  {
    id: 1,
    title: "How are AgriTech Startups Revolutionising Farming?",
    date: "29 Feb 2024, Thursday",
    author: "Dr. Anu Kadyan, Ananya Kumar, and Radhika Kohli",
    images: [image1a, image1b],
  },
  {
    id: 2,
    title: "Growth through Learning with StartupShala",
    date: "11 Jan 2024, Thursday",
    author: "Startup India",
    images: [image2a, image2b],
  },
  {
    id: 3,
    title: "Startup Uncut with Mr. Sanjeev Bikhchandani",
    date: "29 Dec 2023, Friday",
    author: "Startup India",
    images: [image1b, image1a],
  },
  {
    id: 4,
    title: "Exploring Innovative Tech Solutions in Startups",
    date: "15 Nov 2023, Wednesday",
    author: "Tech World",
    images: [image3a, image1a],
  },
  {
    id: 5,
    title: "Sustainable Business Practices for Startups",
    date: "20 Oct 2023, Friday",
    author: "Green Startups",
    images: [image1a, image3a],
  },
];

const rightblogs = [
  {
    id: 1,
    title: "How are AgriTech Startups Revolutionising Farming?",
    date: "29 Feb 2024, Thursday",
    author: "Dr. Anu Kadyan, Ananya Kumar, and Radhika Kohli",
  },
  {
    id: 2,
    title: "Growth through Learning with StartupShala",
    date: "11 Jan 2024, Thursday",
    author: "Startup India",
  },
  {
    id: 3,
    title: "Startup Uncut with Mr. Sanjeev Bikhchandani",
    date: "29 Dec 2023, Friday",
    author: "Startup India",
  },
  {
    id: 4,
    title: "Exploring Innovative Tech Solutions in Startups",
    date: "15 Nov 2023, Wednesday",
    author: "Tech World",
  },
  {
    id: 5,
    title: "Sustainable Business Practices for Startups",
    date: "20 Oct 2023, Friday",
    author: "Green Startups",
  },
];

const BlogPage = (props) => {
  const navigate = useNavigate();

  const [visibleBlogs, setVisibleBlogs] = useState(5); // Left side blogs
  const [visibleRightBlogs, setVisibleRightBlogs] = useState(5); // Right side blogs
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState("Select a Category...");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setSelected(option.category);
    setAnchorEl(null);
    props.viewBlogsByCategory(option._id);
  };

  const handleToggleView = () => {
    if (expanded) {
      setVisibleRightBlogs(3); // Show only initial 3 blogs
    } else {
      setVisibleRightBlogs(props.blog.all_blogsByDate.length); // Show all blogs
    }
    setExpanded(!expanded); // Toggle expanded state
  };

  const handleLoadMore = () => {
    if (expanded) {
      setVisibleBlogs(5); // Show only initial 3 blogs
    } else {
      setVisibleBlogs(props.blog.all_blogs.length); // Show all blogs
    }
    setExpanded(!expanded); // Toggle expanded state
  };

  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  const handleRightBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  const [isOpen, setIsOpen] = useState(false);
  // const ref = useRef(null);

  const toggleIcons = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  useEffect(() => {
    props.viewAllCategoryWithOutPgn("");
    props.viewBlogsByCategory("");
    props.viewBlogsByDates();
    console.log(props.blog.all_blogs);
    console.log(props.blog.all_blogsByDate);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once when the element enters the viewport
    threshold: 0.3, // 30% of the component must be visible to trigger the animation
  });

  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true, // Trigger animation only once when the element enters the viewport
    threshold: 0.3, // 30% of the component must be visible to trigger the animation
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      weekday: "long",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate.replace(",", "");
  };
  // -------------------------------- for descriptions-----------------------------------//

  const [showFullContent, setShowFullContent] = useState(false);

  // Truncate function
  const truncateHtmlContent = (html, wordLimit) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const textContent = tempDiv.textContent || tempDiv.innerText || "";
    const words = textContent.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : textContent;
  };

  return (
    <Box
      sx={{
        paddingBottom: "20px",
        backgroundColor: "#0F1214",
        marginTop: "60px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 700,
          marginBottom: "30px",
          color: "white",
          paddingTop: 3,
        }}
      >
        On The Blogs
      </Typography>

      <Grid container spacing={5} justifyContent="center">
        {/* Left Side:- Blog Cards */}
        <Grid item xs={12} sm={12} md={7}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} alignItems="center">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  height: "45px",
                  backgroundColor: "#fff",
                  paddingBottom: "10px",
                  paddingRight: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    width: "300px",
                  }}
                >
                  <TextField
                    value={selected}
                    variant="outlined"
                    fullWidth
                    onClick={handleClick}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClick}>
                            <FilterAltIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: 20,
                        height: "40px",
                        marginTop: "10px",
                      },
                    }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    MenuListProps={{
                      sx: {
                        width: "300px",
                        padding: 0,
                      },
                    }}
                  >
                    {props.client.viewAllCategorys?.map((option, index) => (
                      <MenuItem
                        key={option._id}
                        onClick={() => handleClose(option)} // Pass the entire option object
                        sx={{
                          fontSize: "16px",
                          padding: "14px 20px",
                          borderBottom:
                            index !== props.client.viewAllCategorys.length - 1
                              ? "1px solid #f0f0f0"
                              : "none",
                          color: index === 0 ? "#a0a0a0" : "#333",
                        }}
                      >
                        {option.category}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Box>
            </Grid>
            {props.blog.all_blogs?.length > 0 ? (
              props.blog.all_blogs?.slice(0, visibleBlogs)?.map((blog) => {
                const truncatedDescription = truncateHtmlContent(
                  blog.htmlContent,
                  40
                ); // Truncate description
                return (
                  <Grid item xs={12} sm={12} key={blog._id} gap="10px">
                    <AnimatedSection>
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          height: { xs: "auto", sm: "250px" },
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          overflow: "hidden",
                          borderRadius: "8px",
                        }}
                      >
                        {/* Carousel */}
                        <Box
                          sx={{
                            width: { xs: "100%", sm: "350px" },
                            height: { xs: "250px", sm: "100%" },
                          }}
                        >
                          <Carousel
                            showThumbs={false}
                            showStatus={false}
                            infiniteLoop
                            emulateTouch
                            autoPlay={false}
                          >
                            {blog.mediaURLs.map((url, index) => {
                              const baseUrl = url.split("?")[0];
                              return baseUrl.includes(".mp4") ? (
                                <Box
                                  key={index}
                                  component="video"
                                  autoPlay
                                  loop
                                  playsInline
                                  muted
                                  sx={{
                                    width: "100%",
                                    height: { sm: "300px", xs: "auto" },
                                    objectFit: "cover",
                                  }}
                                >
                                  <source src={url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </Box>
                              ) : (
                                <Box
                                  component="img"
                                  key={index}
                                  src={url}
                                  alt={`media-${index}`}
                                  sx={{
                                    width: "100%",
                                    height: { sm: "300px", xs: "auto" },
                                    objectFit: "cover",
                                  }}
                                />
                              );
                            })}
                          </Carousel>
                        </Box>

                        {/* Content Section */}
                        <CardContent
                          sx={{
                            flex: "1",
                            padding: "0px 30px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleBlogClick(blog._id)}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              color: "#8D8F99",
                            }}
                          >
                            <AccessTimeIcon
                              sx={{ color: "#EF5C43", fontSize: "15px" }}
                            />{" "}
                            {formatDate(blog.updatedAt)}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 800,
                              fontSize: { xs: "16px", sm: "18px" },
                            }}
                          >
                            {blog.heading}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#8D8F99",
                              marginBottom: "16px",
                            }}
                          >
                            By: {blog.writtenBy}
                          </Typography>

                          {/* Truncated Description */}
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: { xs: "14px", sm: "14px" },
                              lineHeight: 1.5,
                            }}
                          >
                            {showFullContent ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: blog.htmlContent,
                                }}
                              />
                            ) : (
                              <>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: truncatedDescription,
                                  }}
                                />
                              </>
                            )}
                          </Typography>
                        </CardContent>
                      </Card>
                    </AnimatedSection>
                  </Grid>
                );
              })
            ) : (
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  color: "#757575",
                  marginTop: "20px",
                }}
              >
                Blogs not available at the moment.
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoadMore}
                sx={{
                  marginTop: "20px",
                  textTransform: "none",
                  fontWeight: "bold",
                  padding: "18px 40px",
                  boxShadow: "none",
                  background: "rgb(73,145,216)",
                  background:
                    "linear-gradient(0deg, rgb(25, 119, 212) 0%, rgb(98, 115, 249) 100%)",
                }}
              >
                {expanded ? "Load Less" : "Load More"}
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Right Side: Top Blogs */}
        <Grid item xs={12} md={4}>
          <AnimatedSection>
            <Box
              sx={{
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                marginTop: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                TOP BLOGS
              </Typography>
              {props.blog.all_blogsByDate?.length > 0 ? (
                props.blog.all_blogsByDate
                  ?.slice(0, visibleRightBlogs)
                  ?.map((blog) => (
                    <Box
                      key={blog._id}
                      sx={{
                        padding: "20px 0",
                        borderBottom: "1px solid #eee",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRightBlogClick(blog._id)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <AccessTimeIcon
                          sx={{
                            fontSize: "16px",
                            color: "#f26a3e",
                            marginRight: "6px",
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#9e9e9e",
                            fontWeight: 500,
                          }}
                        >
                          {formatDate(blog.updatedAt)}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#333",
                        }}
                      >
                        {blog.heading}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#666",
                          marginTop: "5px",
                        }}
                      >
                        By: {blog.writtenBy}
                      </Typography>
                    </Box>
                  ))
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    color: "#666",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  Blogs not available at the moment.
                </Typography>
              )}
              {props.blog.all_blogsByDate?.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToggleView}
                  sx={{
                    marginTop: "20px",
                    textTransform: "none",
                    fontWeight: "bold",
                    borderRadius: "20px",
                    padding: "8px 20px",
                    boxShadow: "none",
                    background:
                      "linear-gradient(0deg, rgb(25, 119, 212) 0%, rgb(98, 115, 249) 100%)",
                  }}
                >
                  {expanded ? "View Less" : "View More"}
                </Button>
              )}
            </Box>
          </AnimatedSection>
        </Grid>
      </Grid>
      {/* Floating Action Button with Social Icons */}
      <div
        ref={ref}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
            transition: "all 0.3s ease-in-out",
            opacity: isOpen ? 1 : 0,
            transform: isOpen ? "scale(1)" : "scale(0.9)",
            pointerEvents: isOpen ? "all" : "none",
          }}
        >
          {/* WhatsApp */}
          <Tooltip title="WhatsApp" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#25D366", color: "#fff" }}
              onClick={() =>
                window.open("https://wa.me/919583966999", "_blank")
              }
            >
              <WhatsAppIcon />
            </Fab>
          </Tooltip>

          {/* Call */}
          <Tooltip title="Call" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#FF5722", color: "#fff" }}
              onClick={() => window.open("tel:+919583966999", "_self")}
            >
              <CallIcon />
            </Fab>
          </Tooltip>

          {/* Instagram */}
          <Tooltip title="Instagram" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#E1306C", color: "#fff" }}
              onClick={() => window.open("https://www.instagram.com/j9infotech/", "_blank")}
            >
              <InstagramIcon />
            </Fab>
          </Tooltip>

          {/* Facebook */}
          <Tooltip title="Facebook" arrow>
            <Fab
              size="small"
              color="primary"
              onClick={() => window.open("https://www.facebook.com/JNineInfotechh/", "_blank")}
            >
              <FacebookIcon />
            </Fab>
          </Tooltip>

          {/* LinkedIn */}
          <Tooltip title="LinkedIn" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#0077B5", color: "#fff" }}
              onClick={() => window.open("https://www.linkedin.com/company/j-nine-infotech", "_blank")}
            >
              <LinkedInIcon />
            </Fab>
          </Tooltip>
        </div>

        {/* Main "+" Button */}
        <Fab
          color="primary"
          onClick={toggleIcons}
          style={{
            backgroundColor: "#3f51b5",
            color: "#fff",
            transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <AddIcon />
        </Fab>
      </div>
    </Box>
  );
};

export default BlogPage;
