import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Divider,
  IconButton,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import logo1 from "../../images/j_nine_infotech_logo.jpg";

function Home() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showDrawerIcon, setShowDrawerIcon] = useState(false);
  const { pathname } = useLocation();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Detect scroll position
  useEffect(() => {
    const handleScroll = () => {
      setShowDrawerIcon(window.scrollY > 50); // Show drawer icon after scrolling 50px
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      {/* Navbar */}
      <AppBar
        position="fixed"
        elevation={1}
        sx={{
          backgroundColor: "rgba(0,0,0,0.9)",
          color: "white",
          overflow: "hidden",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", width: "90%" }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ width: 130 }}
            >
              <img
                src={logo1}
                alt="Logo"
                style={{
                  height: 60,
                  width: 70,
                  verticalAlign: "middle",
                  marginRight: 8,
                }}
              />
            </Box>
          </Link>
          {/* Desktop Menu or Drawer Icon */}
          {isDesktop ? (
            showDrawerIcon ? (
              <IconButton color="inherit" onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            ) : (
              <Box sx={{ display: "flex", gap: 3 }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "white", textDecoration: "none" }}
                  >
                    Home
                  </Typography>
                </Link>
                <Link to="/services" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "white", textDecoration: "none" }}
                  >
                    Services
                  </Typography>
                </Link>
                <Link to="/pricing" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "white", textDecoration: "none" }}
                  >
                    Pricing
                  </Typography>
                </Link>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "white", textDecoration: "none" }}
                  >
                    Contact
                  </Typography>
                </Link>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "white", textDecoration: "none" }}
                  >
                    About Us
                  </Typography>
                </Link>
                <Link to="/blog" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "white", textDecoration: "none" }}
                  >
                    Blogs
                  </Typography>
                </Link>
                <Link to="/career" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "white", textDecoration: "none" }}
                  >
                    Career
                  </Typography>
                </Link>
              </Box>
            )
          ) : (
            <IconButton color="inherit" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile/tablet/desktop */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box
          p={2}
          width="250px"
          textAlign="center"
          role="presentation"
          onClick={toggleDrawer}
          sx={{ color: "white", backgroundColor: "#000" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              width: "220px",
              height: "100px",
              "@media (min-width: 1440px)": {
                width: "260px",
              },
            }}
          >
            <Link to="/">
              <img
                src={logo1}
                alt="mg"
                style={{
                  width: "100px",
                  height: "100px",
                  "@media (max-width: 600px)": {
                    width: "40px",
                  },
                }}
              />
            </Link>
          </Box>

          <Divider sx={{ backgroundColor: "grey", mt: 1 }} />
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="body1" sx={{ py: 2.5 }}>
              Home
            </Typography>
          </Link>
          <Divider sx={{ backgroundColor: "grey", mt: 1 }} />
          <Link
            to="/services"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography variant="body1" sx={{ py: 2.5 }}>
              Services
            </Typography>
          </Link>
          <Divider sx={{ backgroundColor: "grey", mt: 1 }} />
          <Link
            to="/pricing"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography variant="body1" sx={{ py: 2.5 }}>
              Pricing
            </Typography>
          </Link>
          <Divider sx={{ backgroundColor: "grey", mt: 1 }} />
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography variant="body1" sx={{ py: 2.5 }}>
              Contact
            </Typography>
          </Link>
          <Divider sx={{ backgroundColor: "grey", mt: 1 }} />
          <Link to="/about" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="body1" sx={{ py: 2.5 }}>
              About Us
            </Typography>
          </Link>
          <Divider sx={{ backgroundColor: "grey", mt: 1 }} />
          <Link to="/blog" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="body1" sx={{ py: 2.5 }}>
              Blogs
            </Typography>
          </Link>
          <Divider sx={{ backgroundColor: "grey", mt: 1 }} />
          <Link to="/career" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="body1" sx={{ py: 2.5 }}>
              Career
            </Typography>
          </Link>
        </Box>
      </Drawer>
    </div>
  );
}

export default Home;
