import React, { Component } from "react";
import { connect } from "react-redux";
import Career from "../Component/Career";

import { ApplyJob, InternApply, viewAllJobPost } from "../action";
export class Controller extends Component {
  render() {
    return <Career {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
    career: store.career,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    ApplyJob: (
      jobId,
      name,
      mobile,
      email,
      qualification,
      experience,
      annualSal,
      expectationsSal,
      pcompany,
      type,
      resume
    ) => {
      dispatch(
        ApplyJob(
          jobId,
          name,
          mobile,
          email,
          qualification,
          experience,
          annualSal,
          expectationsSal,
          pcompany,
          type,
          resume
        )
      );
    },
    InternApply: (
      jobId,
      internName,
      internMobile,
      internEmail,
      internQualification,
      type,
      internResume
    ) => {
      dispatch(
        InternApply(
          jobId,
          internName,
          internMobile,
          internEmail,
          internQualification,
          type,
          internResume
        )
      );
    },
    viewAllJobPost: () => {
      dispatch(viewAllJobPost());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
