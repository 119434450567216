import { VIEWALLJOBS } from "./constant";
import UNIVERSAL from "../../Config/Config";
import { setLoader, unsetLoader } from "../../Common/loader/action";
import { set_snack_bar } from "../../Common/snackbar/action";
import "firebase/storage";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
//--------------------------------------------------Apply job------------------------------------//
export function ApplyJob(
  jobId,
  name,
  mobile,
  email,
  qualification,
  experience,
  annualSal,
  expectationsSal,
  precompany,
  type,
  resume
) {
  return (dispatch) => {
    dispatch(setLoader(true)); // Show loader

    if (!resume) {
      dispatch(
        set_snack_bar(true, "Please upload a valid resume in PDF format.")
      );
      dispatch(setLoader(false));
      return;
    }

    // Validate if the uploaded file is a PDF
    const validFileType = "application/pdf";
    if (resume.type !== validFileType) {
      dispatch(
        set_snack_bar(true, "Invalid file type. Only PDF files are allowed.")
      );
      dispatch(setLoader(false));
      return;
    }

    // Generate a unique filename for the PDF
    const fileName = `${resume.name}-${Math.random()
      .toString(36)
      .substr(2, 9)}.pdf`;
    const storageRef = ref(getStorage(), `/uploads/${fileName}`);

    // Upload the PDF file
    const uploadTask = uploadBytesResumable(storageRef, resume);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("File upload failed:", error);
        dispatch(set_snack_bar(true, "File could not be uploaded."));
        dispatch(setLoader(false));
      },
      () => {
        // Handle successful upload
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          dispatch(
            applyJobs(
              jobId,
              name,
              mobile,
              email,
              qualification,
              experience,
              annualSal,
              expectationsSal,
              precompany,
              type,
              downloadURL
            )
          );
          dispatch(set_snack_bar(true, "Resume uploaded successfully."));
          dispatch(setLoader(false));
        });
      }
    );
  };
}

export function applyJobs(
  jobId,
  name,
  mobile,
  email,
  qualification,
  experience,
  annualSal,
  expectationsSal,
  precompany,
  type,
  resume
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobApply/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jobId,
        name,
        mobile,
        email,
        qualification,
        experience,
        annualSal,
        expectationsSal,
        precompany,
        type,
        resume,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          //   dispatch(viewAllJobs(token, user_id, "", "", "", ""));
          //   dispatch(viewAllJobs(user_id, token));
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          //   dispatch(viewAllLinks(token, user_id, "", "", "", ""));
          //   dispatch(viewLinkById(user_id, token));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
//----------------------------------------ApplyInternship-----------------------------------------------//
export function InternApply(
  jobId,
  name,
  mobile,
  email,
  qualification,
  type,
  resume
) {
  return (dispatch) => {
    dispatch(setLoader(true)); // Show loader

    if (!resume) {
      dispatch(
        set_snack_bar(true, "Please upload a valid resume in PDF format.")
      );
      dispatch(setLoader(false));
      return;
    }

    // Validate if the uploaded file is a PDF
    const validFileType = "application/pdf";
    if (resume.type !== validFileType) {
      dispatch(
        set_snack_bar(true, "Invalid file type. Only PDF files are allowed.")
      );
      dispatch(setLoader(false));
      return;
    }

    // Generate a unique filename for the PDF
    const fileName = `${resume.name}-${Math.random()
      .toString(36)
      .substr(2, 9)}.pdf`;
    const storageRef = ref(getStorage(), `/uploads/${fileName}`);

    // Upload the PDF file
    const uploadTask = uploadBytesResumable(storageRef, resume);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("File upload failed:", error);
        dispatch(set_snack_bar(true, "File could not be uploaded."));
        dispatch(setLoader(false));
      },
      () => {
        // Handle successful upload
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          dispatch(
            applyInternship(
              jobId,
              name,
              mobile,
              email,
              qualification,
              type,
              downloadURL
            )
          );
          dispatch(set_snack_bar(true, "Resume uploaded successfully."));
          dispatch(setLoader(false));
        });
      }
    );
  };
}

export function applyInternship(
  jobId,
  name,
  mobile,
  email,
  qualification,
  type,
  resume
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobApply/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jobId,
        name,
        mobile,
        email,
        qualification,
        type,
        resume,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          //   dispatch(viewAllJobs(token, user_id, "", "", "", ""));
          //   dispatch(viewAllJobs(user_id, token));
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          //   dispatch(viewAllLinks(token, user_id, "", "", "", ""));
          //   dispatch(viewLinkById(user_id, token));
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function viewAllJobPost() {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "jobPost/alljobs", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.statusCode === 200 ||
          responseJson.statusCode === 201
        ) {
          dispatch({
            type: VIEWALLJOBS,
            payload: responseJson.data.data,
          });
          dispatch(unsetLoader());
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else if (
          responseJson.statusCode === 401 ||
          responseJson.statusCode === 404
        ) {
          dispatch({ type: VIEWALLJOBS, payload: [] });
          console.log("data Not Found");
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        // dispatch(unsetLoader());
        // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
