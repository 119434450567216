import React, { Component } from "react";
import { connect } from "react-redux";
import Blogpost from "../Components/blogpost";

import {
  viewAllBlog,
  uploadImaes,
  updateContactStatus,
  uploadupdatesImages,
  deleteContact,
  SaveContent,
} from "../action";
export class Controller extends Component {
  render() {
    return <Blogpost {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllBlog: (token, user_id) => {
      dispatch(viewAllBlog(token, user_id));
    },
    SaveContent: (
      // token,
      // user_id,
      htmlContent
    ) => {
      dispatch(
        SaveContent(
          // token,
          // user_id,
          htmlContent
        )
      );
    },

    updateContactStatus: (token, user_id, id, status, page, pageSize) => {
      dispatch(updateContactStatus(token, user_id, id, status, page, pageSize));
    },
    deleteContact: (token, user_id, id, page, pageSize) => {
      dispatch(deleteContact(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
