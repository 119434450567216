import React, { Component } from "react";
import { connect } from "react-redux";
import Addblogs from "../Components/addblogs";

import { SaveBlog, viewAllCategoryWithOutPgn } from "../action";
export class Controller extends Component {
  render() {
    return <Addblogs {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    SaveBlog: (
      token,
      user_id,
      category,
      imageFiles,
      heading,
      writtenBy,
      htmlContent
    ) => {
      dispatch(
        SaveBlog(
          token,
          user_id,
          category,
          imageFiles,
          heading,
          writtenBy,
          htmlContent
        )
      );
    },
    viewAllCategoryWithOutPgn: (token) => {
      dispatch(viewAllCategoryWithOutPgn(token));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
