import React, { Component } from "react";
import { connect } from "react-redux";
import Blogpage from "../Components/Blogpage";

import { viewBlogById ,viewBlogsByDates} from "../action";
export class Controller extends Component {
  render() {
    return <Blogpage {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
    blog: store.blog,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewBlogById: (id) => {
      dispatch(viewBlogById(id));
    },
     viewBlogsByDates: () => {
            dispatch(viewBlogsByDates());
          },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
