import {
  VIEWJOBSBYID,
  ALLJOBS,
  ALLCONTACTUS,
  ALLBOLOGS,
  VIEWALLAPPLICATIONS,
  VIEWALLCATEGORY,
  VIEWALLBLOG,
  VIEWALLCATEGORYS,
} from "./constant";
const initial_state = {
  all_jobslength: 0,
  all_jobs: [],
  all_contactlength: 0,
  all_contactUs: [],
  allblogpost: [],
  allblogpostLength: 0,
  viewjobsById: {},
  viewAllApplications: [],
  viewAllApplicationsLength: 0,
  viewAllCategory: [],
  viewAllCategoryLength: 0,
  viewAllBlogLength: 0,
  viewAllBlog: [],
  viewAllCategorys: [],
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case ALLJOBS:
      return (state = {
        ...state,
        all_jobs: action.payload,
        all_jobslength: action.count,
      });

    case ALLCONTACTUS:
      return (state = {
        ...state,
        all_contactUs: action.payload,
        all_contactlength: action.count,
      });
    case ALLBOLOGS:
      return (state = {
        ...state,
        allblogpost: action.payload,
        allblogpostLength: action.count,
      });

    case VIEWJOBSBYID:
      return (state = {
        ...state,
        viewjobsById: action.payload,
      });
    case VIEWALLAPPLICATIONS:
      return (state = {
        ...state,
        viewAllApplications: action.payload,
        viewAllApplicationsLength: action.count,
      });
    case VIEWALLCATEGORY:
      return (state = {
        ...state,
        viewAllCategory: action.payload,
        viewAllCategoryLength: action.count,
      });
    case VIEWALLCATEGORYS:
      return (state = {
        ...state,
        viewAllCategorys: action.payload,
      });
    case VIEWALLBLOG:
      return (state = {
        ...state,
        viewAllBlog: action.payload,
        viewAllBlogLength: action.count,
      });
    default:
      return state;
  }
}
