import React, { Component } from "react";
import { connect } from "react-redux";
import Blogcategory from "../Components/blogcategory";

import {
  viewAllCategory,
  EditCategory,
  addCategory,
  deleteCategory,
} from "../action";
export class Controller extends Component {
  render() {
    return <Blogcategory {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllCategory: (
      token,
      user_id,
      searchInput,
      page,
      pageSize,
      startDate,
      endDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllCategory(
          token,
          user_id,
          searchInput,
          page,
          pageSize,
          startDate,
          endDate,
          selectedStatus
        )
      );
    },

    addCategory: (token, user_id, categoryName, page, pageSize) => {
      dispatch(addCategory(token, user_id, categoryName, page, pageSize));
    },
    EditCategory: (token, user_id, id, categoryName, page, pageSize) => {
      dispatch(EditCategory(token, user_id, id, categoryName, page, pageSize));
    },
    deleteCategory: (token, user_id, id, page, pageSize) => {
      dispatch(deleteCategory(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
