import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Containers/Controller_Cont.js";
// import Drawer from "../Containers/DrawerCont.js";
import Navbar from "../../Pages/Home/Component/Nav.js";
import Home from "../../Pages/Home/Component/Home.js";
import Pricing from "../../Pages/Pricing/Components/Pricing1.js";
import Service from "../../Pages/Service/Component/service.js";
import Contact from "../../Pages/contact/Component/contact.js";
import About from "../../Pages/About/Components/about.js";
import Footer from "../../Pages/Home/Component/Footer.js";
import Login from "../../Auth/Containers/Login_Cont.js";
// import Navbar from '../../Pages/Home/Component/Nav.js'
// import Home from '../../Pages/Home/Component/Home.js'
// import Pricing from '../../Pages/Pricing/Components/Pricing1.js';
// import Service from '../../Pages/Service/Component/service.js'
// import Contact from '../../Pages/contact/Component/contact.js';
import Blog from "../../Pages/Blogs/Container/blogCon.js";
// import About from '../../Pages/About/Components/about.js';
// import Footer from '../../Pages/Home/Component/Footer.js';
import Blogpage from "../../Pages/Blogs/Container/blogpageCon.js";
// import Profile from "../../Pages/Profile/Containers/ProfileCont.js";
import Blogpost from "../../Client/Containers/blogpostCon.js";
import Addblogs from "../../Client/Containers/addblogCon.js";
import Career from "../../Pages/Career/Container/careerCon.js";
// import Blog1 from "../../Pages/Blogs/Components/Blogpage.js";
import Admindashboard from "../../Client/Components/Dashboard.js";
import Adminnav from "../../Client/Components/Navbar.js";
import Jobpage from "../../Client/Containers/jobCon.js";
import EditJobDetails from "../../Client/Containers/editJobdetailsCon.js";
import Addjobpage from "../../Client/Containers/addjobs.js";
import Blogcategory from "../../Client/Containers/blogCategoryCon.js";
import Contact_details from "../../Client/Components/ContactDetails.js";
import Applied from "../../Client/Containers/appliedCon.js";
class Routers extends Component {
  render() {
    const { login } = this.props;
    let body;
    console.log(login.type);

    if (login.isHome) {
      body = (
        <div>
          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route
                path="/admin"
                element={
                  <>
                    <Login />
                  </>
                }
              />
              <Route
                path="/"
                element={
                  <>
                    <Navbar />
                    <Home />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/pricing"
                element={
                  <>
                    <Navbar />
                    <Pricing />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/services"
                element={
                  <>
                    <Navbar />
                    <Service />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/contact"
                element={
                  <>
                    <Navbar />
                    <Contact />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/about"
                element={
                  <>
                    <Navbar />
                    <About />
                    <Footer />
                  </>
                }
              />

              <Route
                path="/career"
                element={
                  <>
                    <Navbar />
                    <Career />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/pricing"
                element={
                  <>
                    <Navbar />
                    <Pricing />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/services"
                element={
                  <>
                    <Navbar />
                    <Service />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/contact"
                element={
                  <>
                    <Navbar />
                    <Contact />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/about"
                element={
                  <>
                    <Navbar />
                    <About />
                    <Footer />
                  </>
                }
              />

              <Route
                path="/blog/:id"
                element={
                  <>
                    <Navbar />
                    <Blogpage />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/pricing"
                element={
                  <>
                    <Navbar />
                    <Pricing />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/services"
                element={
                  <>
                    <Navbar />
                    <Service />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/contact"
                element={
                  <>
                    <Navbar />
                    <Contact />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/about"
                element={
                  <>
                    <Navbar />
                    <About />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/blog"
                element={
                  <>
                    <Navbar />
                    <Blog />
                    <Footer />
                  </>
                }
              />

              {/* //-----------------------------For Admin-----------------------------------------// */}
            </Routes>
          </main>
        </div>
      );
    } else {
      switch (login.type) {
        case "SA":
          console.log(login.type);
          body = (
            <div>
              {/* <Drawer type={login.type} /> */}
              <main style={{ flexGrow: 1, paddingTop: 0 }}>
                <Controller />
                <Routes>
                  {" "}
                  <Route
                    path="/admin"
                    element={
                      <>
                        {/* <Adminnav/> */}
                        <Admindashboard />
                      </>
                    }
                  />
                  <Route
                    path="/addblogs"
                    element={
                      <>
                        <Addblogs />
                      </>
                    }
                  />
                  <Route
                    path="/blogpost"
                    element={
                      <>
                        <Blogpost />
                      </>
                    }
                  />
                  <Route
                    path="/job"
                    element={
                      <>
                        <Adminnav />
                        <Jobpage />
                      </>
                    }
                  />
                  <Route
                    path="/editjobdetails/:id/:jobTypes"
                    element={
                      <>
                        <Adminnav />
                        <EditJobDetails />
                      </>
                    }
                  />
                  <Route
                    path="/addjobs"
                    element={
                      <>
                        <Adminnav />
                        <Addjobpage />
                      </>
                    }
                  />
                  <Route
                    path="/blog-category"
                    element={
                      <>
                        <Adminnav />
                        <Blogcategory />
                      </>
                    }
                  />
                  <Route
                    path="/contact-details"
                    element={
                      <>
                        <Adminnav />
                        <Contact_details />
                      </>
                    }
                  />
                  <Route
                    path="/applied"
                    element={
                      <>
                        <Adminnav />
                        <Applied />
                      </>
                    }
                  />
                </Routes>
              </main>
            </div>
          );
          break;
        default:
          body = (
            <div>
              {/* <Drawer type={login.type} /> */}
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes></Routes>
              </main>
            </div>
          );
          break;
      }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;
