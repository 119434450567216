import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import Image1 from '../../images/about.jpg';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useRef } from 'react';
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const AnimatedSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.2,    // Triggers animation when 20% of the component is visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, delay }}
    >
      {children}
    </motion.div>
  );
};
const About = () => {
  const sectionRef = useRef(null);
  const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const toggleIcons = () => {
      setOpen(!open);
    };
  
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
  
    useEffect(() => {
      if (open) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [open]);
  return (
    <Box p={4} sx={{backgroundColor:'#0F1214'}}>
      {/* Content1 */}
      <motion.div
      ref={sectionRef}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}  // 1-second duration for all elements
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction={{ xs: 'column', md: 'row' }}
        color="white"
        marginTop="50px"
      >
        {/* Left Text Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: '900' }}>
            About J Nine Infotech
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to J Nine Infotech, your partner in innovative IT and software solutions. Since our establishment in 2021, we have been dedicated to empowering businesses across India by delivering tailored, technology-driven solutions that inspire growth and success.
          </Typography>
          <Typography variant="h5" fontWeight="800">
            Who we are?
          </Typography>
          <Typography variant="body1" paragraph>
            With a customer-first approach and a passion for innovation, we aim to simplify complex technological challenges while enhancing operational efficiency and digital presence. From crafting stunning websites to developing intuitive mobile apps, we deliver solutions designed to help businesses thrive in today’s competitive landscape.
          </Typography>
          <Typography variant="body1" paragraph>
            At J Nine Infotech, we are a team of passionate and skilled professionals committed to delivering top-notch web solutions tailored to meet your unique needs. Our expertise lies in website development and design, and we take pride in crafting visually appealing, highly functional, and user-friendly websites that leave a lasting impression.
          </Typography>
        </Grid>

        {/* Right Image Section */}
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <img
            src={Image1}
            alt="Description"
            style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'cover',
              maxHeight: '400px',
              borderRadius: '8px',
            }}
          />
        </Grid>
      </Grid>
    </motion.div>

      {/* Content2 */}
      <AnimatedSection>
        <Box mt={4} sx={{ color: 'white' }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: '900' }}>
          Our Mission
          </Typography>
          <Typography>
          Our mission is to empower businesses with cost-effective, scalable, and result-oriented IT solutions, enabling them to achieve sustainable growth in the digital era.
          </Typography>
          <Box sx={{ maxWidth: 800, p: 1 }}>
      <Box component="h2" sx={{  mb: 2 }}>
        Why Choose J Nine Infotech?
      </Box>
      <Box component="ul" sx={{ listStyleType: 'none', p: 0 }}>
        <Box component="li" sx={{ mb: 1 }}>
          • Proven track record of delivering innovative IT solutions.
        </Box>
        <Box component="li" sx={{ mb: 1 }}>
          • Nationwide presence with a focus on personalized service.
        </Box>
        <Box component="li" sx={{ mb: 1 }}>
          • Commitment to excellence, scalability, and customer satisfaction.
        </Box>
        <Box component="li" sx={{ mb: 1 }}>
          • Expertise in multiple domains, including technology, marketing, and analytics.
        </Box>
    </Box>
          </Box>
        </Box>
      </AnimatedSection>

        {/* content 3 */}
        <AnimatedSection>
        <Box sx={{ padding: '20px', color: '#fff' }}>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold',color:'white' }}>
      Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
      We are here to help you transform your ideas into reality. Get in touch with us today!
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginTop: '30px' }}>
      Office Address:
      </Typography>
      <Typography variant="body1" fontWeight='800'>
      J Nine Infotech,<br/>
      Patia,Bhubaneswar, Odisha – 751001
      </Typography>

      <Typography variant="body1" fontWeight='800' marginTop="15px">
      Email: info@jnine.com<br/>
      Phone: +91 9583966999<br/>
      Website: www.jnineinfotech.com
      </Typography>

      <Typography variant="body1" paragraph sx={{ marginTop: '20px' }}>
      Let’s build the future together! Reach out to us today and take the first step toward achieving your business goals with J Nine Infotech.
      </Typography>
    </Box>
    </AnimatedSection>
          {/* Floating Action Button with Social Icons */}
          <div
        ref={ref}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
            transition: "all 0.3s ease-in-out",
            opacity: open ? 1 : 0,
            transform: open ? "scale(1)" : "scale(0.9)",
            pointerEvents: open ? "all" : "none",
          }}
        >
          {/* WhatsApp */}
          <Tooltip title="WhatsApp" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#25D366", color: "#fff" }}
              onClick={() => window.open("https://wa.me/919583966999", "_blank")}
            >
              <WhatsAppIcon />
            </Fab>
          </Tooltip>

          {/* Call */}
          <Tooltip title="Call" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#FF5722", color: "#fff" }}
              onClick={() => window.open("tel:+919583966999", "_self")}
            >
              <CallIcon />
            </Fab>
          </Tooltip>

          {/* Instagram */}
          <Tooltip title="Instagram" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#E1306C", color: "#fff" }}
              onClick={() => window.open("https://instagram.com", "_blank")}
            >
              <InstagramIcon />
            </Fab>
          </Tooltip>

          {/* Facebook */}
          <Tooltip title="Facebook" arrow>
            <Fab
              size="small"
              color="primary"
              onClick={() => window.open("https://facebook.com", "_blank")}
            >
              <FacebookIcon />
            </Fab>
          </Tooltip>

          {/* LinkedIn */}
          <Tooltip title="LinkedIn" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#0077B5", color: "#fff" }}
              onClick={() => window.open("https://linkedin.com", "_blank")}
            >
              <LinkedInIcon />
            </Fab>
          </Tooltip>
        </div>

        {/* Main "+" Button */}
        <Fab
          color="primary"
          onClick={toggleIcons}
          style={{
            backgroundColor: "#3f51b5",
            color: "#fff",
            transform: open ? "rotate(45deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <AddIcon />
        </Fab>
      </div>
    </Box>
  );
};

export default About;
