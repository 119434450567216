import React, { Component } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  TextField,
  Button,
  InputAdornment,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  Divider,
  MenuItem,
  Avatar,
  FormControl,
  InputLabel,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Visibility } from "@mui/icons-material";
import { Delete, Edit } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import { Search as SearchIcon } from "@mui/icons-material";
import "./addes.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      add: false,
      edit: false,
      reject: false,
      selectedBlogs: null,
      searchInput: "",
      page: 0,
      rowsPerPage: 10,
      contact_id: "",
      selectedDate: "",
      selectedStatus: "",
      editStatus: false,
      status: "",
    };
    this.modules = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline", "strike"], // Formatting options
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image", "video"], // Adding image and video options
          ["clean"], // Clear formatting
        ],
        handlers: {
          image: this.handleImageUpload,
          video: this.handleVideoUpload,
        },
      },
    };

    this.formats = [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "list",
      "bullet",
      "link",
      "image",
      "video",
    ];

    // this.debouncedSearch = _.debounce(this.handleSearch, 300);
  }

  handleDialogOpen = (employee) => {
    this.setState({ selectedBlogs: employee, view: true });
  };

  handleDialogClose = () => {
    this.setState({ view: false, editStatus: false, selectedBlogs: null });
  };

  componentDidMount() {
    this.props.viewAllBlog(this.props.login.token, this.props.login.user_id);
  }

  handleContentChange = (content) => {
    this.setState({ htmlContent: content });
  };

  // Handle form submission
  handleSubmit = (e) => {
    e.preventDefault();
    console.log("HTML Content:", this.state.htmlContent);
    this.props.SaveContent(this.state.htmlContent);
  };

  // Handle image upload
  handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const range = this.quillRef.getEditor().getSelection();
          this.quillRef
            .getEditor()
            .insertEmbed(range.index, "image", reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  };

  // Handle video upload from file or URL
  handleVideoUpload = () => {
    const type = prompt(
      "Choose video type: \n1. Upload file\n2. Paste video URL"
    );
    if (type === "1") {
      // Video upload via file
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "video/*");
      input.click();

      input.onchange = () => {
        const file = input.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const range = this.quillRef.getEditor().getSelection();
            this.quillRef
              .getEditor()
              .insertEmbed(range.index, "video", reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    } else if (type === "2") {
      // Video embed via URL
      const url = prompt("Enter the video URL:");
      if (url) {
        const range = this.quillRef.getEditor().getSelection();
        this.quillRef.getEditor().insertEmbed(range.index, "video", url);
      }
    } else {
      alert("Invalid option. Please choose either 1 or 2.");
    }
  };

  render() {
    const {
      searchInput,
      selectedBlogs,
      view,
      add,
      reject,
      contact_id,
      page,
      rowsPerPage,
      editStatus,
      status,
    } = this.state;
    const { htmlContent } = this.state;
    return (
      <Grid
        container
        spacing={2}
        style={{ alignItems: "center", marginTop: "10px" }}
      >
        <Grid container justifyContent="flex-end">
          <Link to="/addblogs">
            <Button
              // onClick={(e) => {
              //   this.setState({ add: true });
              // }}
              color="primary"
              variant="contained"
            >
              Blog Post
            </Button>
          </Link>
        </Grid>

        {/* Advertisement Table */}
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            style={{
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                  {[
                    "Sl No",
                    "Heading",
                    "Category",
                    "Media",
                    "Written By",
                    "Date",
                    "Status",
                    "Action",
                  ].map((header, index) => (
                    <TableCell key={index} align="center">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        {header}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(this.props.client.viewAllBlog) &&
                  this.props.client.viewAllBlog.map((item, index) => {
                    const createdAt = new Date(item.createdAt);
                    const date = createdAt.toLocaleDateString(); // Formats date as MM/DD/YYYY
                    const time = createdAt.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    }); // Formats time as HH:MM AM/PM

                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#ffffff" : "#f9f9f9",
                        }}
                      >
                        <TableCell align="center">
                          <Typography variant="body2">{index + 1}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">
                            {item.heading}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">
                            {item.category.category}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">
                            {item.mediaURLs.length}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">
                            {item.writtenBy}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">{date}</Typography>
                          <Typography variant="body2">{time}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">
                            {item.status}
                            {/* <Tooltip title="Edit" arrow>
                              <IconButton
                                onClick={(e) => {
                                  this.setState({
                                    editStatus: true,
                                    selectedBlogs: item,
                                  });
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip> */}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="View Details" arrow>
                            <IconButton
                              onClick={() => this.handleDialogOpen(item)}
                            >
                              <Visibility />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete" arrow>
                            <IconButton
                              onClick={() => {
                                // this.toggleStatus(item);
                                this.setState({
                                  reject: true,
                                  contact_id: item._id,
                                });
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* View Details Dialog */}
        <Dialog
          open={view}
          onClose={this.handleDialogClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Contact Us Details</DialogTitle>
          <DialogContent>
            {selectedBlogs && (
              <Paper
                elevation={3}
                style={{ padding: "20px", borderRadius: "8px" }}
              >
                <Grid container spacing={2}>
                  {/* Profile Avatar */}
                  <Grid item xs={12} container justifyContent="center">
                    <Avatar
                      alt={`${selectedBlogs.category.category}`}
                      src={selectedBlogs.avatarUrl || "default-avatar.png"} // Default avatar if not provided
                      sx={{ width: 56, height: 56, marginBottom: "16px" }}
                    />
                  </Grid>

                  {/* Employee Name */}
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                      {selectedBlogs.category.category}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {/* Contact Information */}
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Heading:</strong> {selectedBlogs.heading}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Written By:</strong> {selectedBlogs.writtenBy}
                    </Typography>
                  </Grid>

                  {/* Media Section */}

                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Media:</strong>
                    </Typography>
                    <Grid item xs={12}>
                      {selectedBlogs.mediaURLs.map((url, index) => {
                        // Extract the path without query parameters
                        const baseUrl = url.split("?")[0];

                        return baseUrl.includes(".mp4") ? (
                          <video
                            key={index}
                            controls
                            style={{
                              maxWidth: "100%",
                              borderRadius: "8px",
                              marginBottom: "16px",
                              pointerEvents: "none",
                            }}
                            autoPlay
                            loop
                            muted
                          >
                            <source src={url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            key={index}
                            src={url}
                            alt={`media-${index}`}
                            style={{
                              maxWidth: "100%",
                              borderRadius: "8px",
                              marginBottom: "16px",
                            }}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>

                  {/* Status */}
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Status:</strong> {selectedBlogs.status}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.reject}
          onClose={() => {
            this.setState({ reject: false, contact_id: "" });
          }}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to Delete the Contact Details?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                this.props.deleteContact(
                  this.props.login.token,
                  this.props.login.user_id,
                  this.state.contact_id,
                  this.state.page,
                  this.state.rowsPerPage
                );
                this.setState({ reject: false, contact_id: "" });
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={() => this.setState({ reject: false })}
              color="secondary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editStatus}
          onClose={this.handleDialogClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Edit Status</DialogTitle>
          <DialogContent>
            {selectedBlogs && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      value={status}
                      onChange={(e) => {
                        this.setState({ status: e.target.value });
                      }}
                      label="Status"
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="In Progress">In Progress</MenuItem>
                      <MenuItem value="Resolved">Resolved</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                this.props.updateContactStatus(
                  this.props.login.token,
                  this.props.login.user_id,
                  selectedBlogs._id,
                  this.state.status,
                  this.state.page,
                  this.state.rowsPerPage
                );
                this.setState({ editStatus: false, status: "" });
              }}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withParams(Employee);
