import React, { Component } from "react";
import { connect } from "react-redux";
import EditJobdetails from "../Components/editJobdetails";

import { editJobs ,viewJobsById} from "../action";
export class Controller extends Component {
  render() {
    return <EditJobdetails {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    editJobs: (
      token,
      user_id,
      job_id,
      jobType,
      designation,
      location,
      skills,
      Interntype,
      Price,
      experience,
      days
    ) => {
      dispatch(
        editJobs(
          token,
          user_id,
          job_id,
          jobType,
          designation,
          location,
          skills,
          Interntype,
          Price,
          experience,
          days
        )
      );
    },
    viewJobsById: (
        token,
        user_id,
        job_id,
       
      ) => {
        dispatch(
            viewJobsById(
            token,
            user_id,
            job_id,
          
          )
        );
      },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
