import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import image1a from "../../images/blogback.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import TwitterIcon from "@mui/icons-material/Twitter";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
const AnimatedSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.2, // Triggers animation when 20% of the component is visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, delay }}
    >
      {children}
    </motion.div>
  );
};

const rightblogs = [
  {
    id: 1,
    title: "How are AgriTech Startups Revolutionising Farming?",
    date: "29 Feb 2024, Thursday",
    author: "Dr. Anu Kadyan, Ananya Kumar, and Radhika Kohli",
  },
  {
    id: 2,
    title: "Growth through Learning with StartupShala",
    date: "11 Jan 2024, Thursday",
    author: "Startup India",
  },
  {
    id: 3,
    title: "Startup Uncut with Mr. Sanjeev Bikhchandani",
    date: "29 Dec 2023, Friday",
    author: "Startup India",
  },
  {
    id: 4,
    title: "Exploring Innovative Tech Solutions in Startups",
    date: "15 Nov 2023, Wednesday",
    author: "Tech World",
  },
  {
    id: 5,
    title: "Sustainable Business Practices for Startups",
    date: "20 Oct 2023, Friday",
    author: "Green Startups",
  },
];

const BlogPage = (props) => {
  const navigate = useNavigate();

  const [visibleRightBlogs, setVisibleRightBlogs] = useState(5); // Right side blogs
  const [expanded, setExpanded] = useState(false);
  const { id } = useParams();
  const handleToggleView = () => {
    if (expanded) {
      setVisibleRightBlogs(5); // Show only initial 3 blogs
    } else {
      setVisibleRightBlogs(props.blog.all_blogsByDate.length); // Show all blogs
    }
    setExpanded(!expanded); // Toggle expanded state
  };

  const handleRightBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const toggleIcons = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    props.viewBlogById(id);
    props.viewBlogsByDates();
    console.log(props.blog.blogById);
  }, []);
  const sanitizedHtml = DOMPurify.sanitize(props.blog.blogById.htmlContent, {
    ALLOWED_TAGS: [
      "b",
      "i",
      "u",
      "a",
      "img",
      "ul",
      "ol",
      "li",
      "p",
      "br",
      "strong",
      "em",
    ], // Allow img tag and other common HTML tags
    ALLOWED_ATTR: ["href", "src", "alt", "title"], // Allow specific attributes for the tags, like src for images
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      weekday: "long",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate.replace(",", "");
  };
  return (
    <Box
      sx={{
        paddingBottom: "20px",
        backgroundColor: "#0F1214",
        marginTop: "60px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 700,
          marginBottom: "30px",
          color: "white",
          paddingTop: 3,
        }}
      >
        On The Blogs
      </Typography>

      <Grid container spacing={5} justifyContent="center">
        {/* Left Side:- Blog Cards */}
        <Grid item xs={12} sm={12} md={7}>
          <Grid container spacing={3}>
            <Card
              sx={{
                margin: 4,
                boxShadow: 3,
                borderRadius: "10px",
                overflow: "hidden",
                backgroundColor: "#ffffff",
              }}
            >
              <CardContent sx={{ textAlign: "center", padding: 4 }}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat, Sans-serif",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 800,
                    gap: "8px",
                  }}
                >
                  By:{" "}
                  <p style={{ color: "#1AA986" }}>
                    {props.blog.blogById.writtenBy}
                  </p>
                </Typography>
                {/* Title Section */}
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    color: "#3D3F54",
                    fontFamily: "Montserrat, Sans-serif",
                    textAlign: "left",
                    fontSize: { xs: "20px", sm: "30px" },
                  }}
                >
                  {props.blog.blogById.heading}
                </Typography>
              </CardContent>

              {/* Full-Size Image */}
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                emulateTouch
                autoPlay={false}
              >
                {props.blog.blogById.mediaURLs.map((url, index) => {
                  const baseUrl = url.split("?")[0];

                  return baseUrl.includes(".mp4") ? (
                    <Box
                      key={index}
                      component="video"
                      autoPlay
                      loop
                      playsInline
                      controls 
                      sx={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    >
                      <source src={url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </Box>
                  ) : (
                    <Box
                      component="img"
                      src={url}
                      alt="banner"
                      sx={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  );
                })}
              </Carousel>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Montserrat, Sans-serif",
                  color: "#6B7083",
                  p: 3,
                }}
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
              ></Typography>

              {/* <Typography
                variant="body1"
                sx={{
                  fontFamily: "Montserrat, Sans-serif",
                  color: "#6B7083",
                  p: 3,
                }}
              >
                The Agritech industry has experienced a remarkable tenfold
                growth in the past three years, propelled by four pivotal
                factors: the expanding digital reach throughout India, supply
                chain disruptions due to COVID, rising consumer demand for
                high-quality produce, and growing interest from private equity
                and venture capital.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Montserrat, Sans-serif",
                  color: "#6B7083",
                  p: 3,
                }}
              >
                Currently, there are nearly 2800[Startup India Database as on
                31st December 2023] AgriTech startups recognised by Startup
                India. These AgriTech startups in India have been termed as the
                “ray of hope”, driving innovation and transforming the way
                agriculture is traditionally done in India. Here are some of the
                areas where AgriTech startups are making a difference.
              </Typography> */}

              <Divider sx={{ backgroundColor: "grey", mt: 1 }} />

              {/* Social Media Links */}
              {/* <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={1}
                sx={{ marginTop: 4, pl: 4, pb: 2 }}
              >
                <Typography
                  sx={{ fontFamily: "Montserrat, Sans-serif", fontWeight: 800 }}
                >
                  Share:{" "}
                </Typography>
                <IconButton
                  onClick={() =>
                    window.open("https://www.facebook.com", "_blank")
                  }
                  sx={{
                    color: "#3b5998",
                    fontSize: "50px",
                  }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open("https://www.instagram.com", "_blank")
                  }
                  sx={{
                    color: "#C13584",
                    fontSize: "50px",
                  }}
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open("https://www.twitter.com", "_blank")
                  }
                  sx={{
                    color: "#1DA1F2",
                    fontSize: "30px",
                  }}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open("https://www.linkedin.com", "_blank")
                  }
                  sx={{
                    color: "#0077b5",
                    fontSize: "30px",
                  }}
                >
                  <LinkedInIcon />
                </IconButton>
              </Stack> */}
            </Card>

            {/* Scroll to Top Button */}
            <IconButton
              sx={{
                position: "fixed",
                bottom: "20px",
                left: "20px",
                backgroundColor: "#1AA986",
                color: "#ffffff",
                width: "80px",
                height: "80px",
                fontSize: "40px",
                "&:hover": {
                  backgroundColor: "#166f5c",
                },
              }}
              onClick={scrollToTop}
            >
              <ArrowUpwardIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>

        {/* Right Side: Top Blogs */}
        <Grid item xs={12} md={4}>
          <AnimatedSection>
            <Box
              sx={{
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                marginTop: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                TOP BLOGS
              </Typography>
              {props.blog.all_blogsByDate?.slice(0, visibleRightBlogs).map((blog) => (
                <Box
                  key={blog.id}
                  sx={{
                    padding: "20px 0",
                    borderBottom: "1px solid #eee",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRightBlogClick(blog._id)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <AccessTimeIcon
                      sx={{
                        fontSize: "16px",
                        color: "#f26a3e",
                        marginRight: "6px",
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#9e9e9e",
                        fontWeight: 500,
                      }}
                    >
                      {formatDate(blog.updatedAt)}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#333",
                    }}
                  >
                    {blog.heading}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#666",
                      marginTop: "5px",
                    }}
                  >
                    By: {blog.writtenBy}
                  </Typography>
                </Box>
              ))}
              {rightblogs.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToggleView}
                  sx={{
                    marginTop: "20px",
                    textTransform: "none",
                    fontWeight: "bold",
                    borderRadius: "20px",
                    padding: "8px 20px",
                    boxShadow: "none",
                    background: "rgb(73,145,216)",
                    background:
                      "linear-gradient(0deg, rgb(25, 119, 212) 0%, rgb(98, 115, 249) 100%)",
                  }}
                >
                  {expanded ? "View Less" : "View More"}
                </Button>
              )}
            </Box>
          </AnimatedSection>
        </Grid>
      </Grid>
      {/* Floating Action Button with Social Icons */}
      <div
        ref={ref}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
            transition: "all 0.3s ease-in-out",
            opacity: open ? 1 : 0,
            transform: open ? "scale(1)" : "scale(0.9)",
            pointerEvents: open ? "all" : "none",
          }}
        >
          {/* WhatsApp */}
          <Tooltip title="WhatsApp" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#25D366", color: "#fff" }}
              onClick={() =>
                window.open("https://wa.me/919583966999", "_blank")
              }
            >
              <WhatsAppIcon />
            </Fab>
          </Tooltip>

          {/* Call */}
          <Tooltip title="Call" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#FF5722", color: "#fff" }}
              onClick={() => window.open("tel:+919583966999", "_self")}
            >
              <CallIcon />
            </Fab>
          </Tooltip>

          {/* Instagram */}
          <Tooltip title="Instagram" arrow>
            <Fab
              size="small"
              color="secondary"
              style={{ backgroundColor: "#E1306C", color: "#fff" }}
              onClick={() => window.open("https://www.instagram.com/j9infotech/", "_blank")}
            >
              <InstagramIcon />
            </Fab>
          </Tooltip>

          {/* Facebook */}
          <Tooltip title="Facebook" arrow>
            <Fab
              size="small"
              color="primary"
              onClick={() => window.open("https://www.facebook.com/JNineInfotechh/", "_blank")}
            >
              <FacebookIcon />
            </Fab>
          </Tooltip>

          {/* LinkedIn */}
          <Tooltip title="LinkedIn" arrow>
            <Fab
              size="small"
              style={{ backgroundColor: "#0077B5", color: "#fff" }}
              onClick={() => window.open("https://www.linkedin.com/company/j-nine-infotech", "_blank")}
            >
              <LinkedInIcon />
            </Fab>
          </Tooltip>
        </div>

        {/* Main "+" Button */}
        <Fab
          color="primary"
          onClick={toggleIcons}
          style={{
            backgroundColor: "#3f51b5",
            color: "#fff",
            transform: open ? "rotate(45deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <AddIcon />
        </Fab>
      </div>
    </Box>
  );
};

export default BlogPage;
