import React, { Component } from "react";
import { connect } from "react-redux";
import Job from "../Components/job";

import { viewAllJobs, deleteJobs ,updateJobsStatus} from "../action";
export class Controller extends Component {
  render() {
    return <Job {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllJobs: (
      token,
      user_id,
      searchInput,
      page,
      rowsPerPage,
      startDate,
      endDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllJobs(
          token,
          user_id,
          searchInput,
          page,
          rowsPerPage,
          startDate,
          endDate,
          selectedStatus
        )
      );
    },
    deleteJobs: (token, user_id, job_id, page, rowsPerPage) => {
      dispatch(deleteJobs(token, user_id, job_id, page, rowsPerPage));
    },
    updateJobsStatus: (token, user_id, job_id, status,page, rowsPerPage) => {
        dispatch(updateJobsStatus(token, user_id, job_id,status, page, rowsPerPage));
      },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
