import React, { Component } from "react";
import { connect } from "react-redux";
import Applied from "../Components/applied";

import {
  viewAllApplication,
  updateApplicationtStatus,
  deleteApplications,
} from "../action";
export class Controller extends Component {
  render() {
    return <Applied {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllApplication: (
      token,
      user_id,
      searchInput,
      page,
      rowsPerPage,
      selectedDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllApplication(
          token,
          user_id,
          searchInput,
          page,
          rowsPerPage,
          selectedDate,
          selectedStatus
        )
      );
    },

    deleteApplications: (token, user_id, id, page, rowsPerPage) => {
      dispatch(deleteApplications(token, user_id, id, page, rowsPerPage));
    },
    updateApplicationtStatus: (token, user_id, id, status, page, rowsPerPage) => {
      dispatch(
        updateApplicationtStatus(token, user_id, id, status, page, rowsPerPage)
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
