import { ALLBOLOG, ALLBLOGBYDATES,VIEWBLOGBYID } from "./constant";
const initial_state = {
  all_blogslength: 0,
  all_blogs: [],
  all_blogsByDate: [],
  all_blogsByDatelength: 0,
  blogById:{}
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case ALLBOLOG:
      return (state = {
        ...state,
        all_blogs: action.payload,
        all_blogslength: action.count,
      });

    case ALLBLOGBYDATES:
      return (state = {
        ...state,
        all_blogsByDate: action.payload,
        all_blogsByDatelength: action.count,
      });
      case VIEWBLOGBYID:
      return (state = {
        ...state,
        blogById: action.payload,
      });
    default:
      return state;
  }
}
