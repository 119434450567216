import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Card,
  CardContent,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
  Avatar,
  Collapse,
  Divider,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
// import centralLogo from "../../Pages/images/download.jpg";
import logoImage from "../../Pages/images/j_nine_infotech_logo.jpg";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import WorkIcon from "@mui/icons-material/Work";
import ContactsIcon from "@mui/icons-material/Contacts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ApprovalIcon from "@mui/icons-material/Approval";
// Register Chart.js components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const drawerWidth = 240;

const Dashboard = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setIsAnchorEl] = useState(null);
  const [open, setIsOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setIsAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsAnchorEl(null);
    setIsOpen(false);
  };
  const [contactData, setContactData] = useState({
    labels: [],
    datasets: [
      {
        label: "Contacts",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  });
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Contacts",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const [searchJobs, setJobssearch] = useState({
    labels: [],
    datasets: [
      {
        label: "Jobs",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  });

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Job Searches",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const [searchInternships, setInternshipsearch] = useState({
    labels: [],
    datasets: [
      {
        label: "Internships",
        data: [],
        backgroundColor: "#E9AF47",
      },
    ],
  });
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Internship Searches",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const [blogData, setBlogData] = useState({
    labels: [],
    datasets: [
      {
        label: "Contacts",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  });
  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Blogs",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  // useEffect(() => {
  //   props.viewOldCarLength(props.login.token, props.login.user_id);
  //   props.viewTestDriveLength(props.login.token, props.login.user_id);
  //   props.viewContactUsLength(props.login.token, props.login.user_id);
  // console.log( props.client.viewContactUsLength.totalLength , "rgba(255, 99, 132, 0.6)");
  // console.log( props.client.viewTestdriveLength , "rgba");
  // console.log( props.client.oldcarlength, "rgba");
  // if (props.client.viewContactUsLength) {
  //   const { labels, counts } = props.client.viewContactUsLength;

  //   setContactData({
  //     labels: labels, // Set labels from API response
  //     datasets: [
  //       {
  //         label: "Contacts",
  //         data: counts, // Set data from counts in API response
  //         backgroundColor: "rgba(255, 99, 132, 0.6)",
  //       },
  //     ],
  //   });
  // }
  //   if (props.client.viewTestdriveLength) {
  //     const { labels, counts1 } = props.client.viewTestdriveLength;

  //     setTestDriveData({
  //       labels: labels, // Set labels from API response
  //       datasets: [
  //         {
  //           label: "TestDrive",
  //           data: counts1, // Set data from counts in API response
  //           backgroundColor: "rgba(54, 162, 235, 0.6)",
  //         },
  //       ],
  //     });
  //   }
  // },
  // [
  //   props.login.token,
  //   props.login.user_id,
  //   props.client.viewContactUsLength,
  //   props.client.viewTestdriveLength,
  // ]);
  const [openCareer, setOpenCareer] = useState(false);
  const navigate = useNavigate();

  const handleCareerClick = () => {
    setOpenCareer(!openCareer);
  };
  const drawer = (
    <List sx={{ marginTop: "100px" }}>
      {[
        { text: "Dashboard", route: "/dashboard", icon: <DashboardIcon /> },
        { text: "Job Post", route: "/job", icon: <AutoStoriesIcon /> },
        
        { text: "Applied", route: "/applied", icon: <ApprovalIcon /> },
        {
          text: "Blog Category",
          route: "/blog-category",
          icon: <FolderCopyIcon />,
        },
        { text: "Blog", route: "/blogpost", icon: <BorderColorIcon /> },
        { text: "Contact", route: "/contact-details", icon: <ContactsIcon /> },
      ].map((item, index, array) => (
        <React.Fragment key={item.text}>
          {item.isDropdown ? (
            <>
              <ListItem button onClick={handleCareerClick}>
                <ListItemIcon sx={{ color: "#fff" }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {/* {openCareer ? <ExpandLess /> : <ExpandMore />} */}
              </ListItem>
              {/* <Collapse in={openCareer} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => navigate("/job")}
                  >
                    <WorkOutlineIcon /> &nbsp; <ListItemText primary="Job" />
                  </ListItem>
                  <ListItem
                    button
                    sx={{ pl: 4 }}
                    onClick={() => navigate("/internship")}
                  >
                    <WorkOutlineIcon /> &nbsp;
                    <ListItemText primary="Internship" />
                  </ListItem>
                </List>
              </Collapse> */}
            </>
          ) : (
            <ListItem button onClick={() => navigate(item.route)}>
              <ListItemIcon sx={{ color: "#fff" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          )}
          {index < array.length - 1 && (
            <Divider sx={{ backgroundColor: "gray" }} />
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <Box sx={{ display: "flex", height: "auto" }}>
      {" "}
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          height: "60px",
          zIndex: 1201,
        }}
      >
        <Toolbar
          sx={{
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
          }}
        >
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          {/* Logo Section */}
          <Box
            component={RouterLink}
            to="/admin"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logoImage}
              alt="mg"
              style={{
                width: "60px",
                height: "auto",
                marginRight: "8px",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt="User Avatar"
              src={props.login?.profile_pic || props.login?.name}
              sx={{ width: 40, height: 40, cursor: "pointer" }}
              onClick={handleMenuOpen}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                component={RouterLink}
                to="/profile"
                onClick={handleMenuClose}
              >
                <AccountCircleIcon sx={{ marginRight: 1 }} />
                Profile
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to="/admin"
                onClick={(e) => {
                  // props.onLogout();
                  handleMenuClose();
                }}
              >
                <LogoutIcon sx={{ marginRight: 1 }} />
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {/* Sidebar for Desktop */}
      {!isMobile && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#101010",
              color: "#fff",
              height: "100vh",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {drawer}
        </Drawer>
      )}
      {/* Sidebar for Mobile */}
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#101010",
            color: "#fff",
            height: "auto",
          },
        }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="left"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
          }}
        >
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
        {drawer}
      </Drawer>
      {/* Main Content */}
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "#000", paddingBottom: "10px" }}
      >
        <Toolbar />
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#fff",
            fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
          }}
        >
          DASHBOARD
        </Typography>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={4}>
            <Card
              sx={{
                backgroundColor: "#3498db",
                color: "#fff",
                height: "200px",
              }}
            >
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "25px", fontWeight: "800" }}
                >
                  Job Post
                </Typography>
                {/* <AutoStoriesIcon size={32} /> */}
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={12} md={4}>
            <Card
              sx={{
                backgroundColor: "#e67e22",
                color: "#fff",
                height: "200px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  height: "200px",
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "25px", fontWeight: "800" }}
                >
                  Blogs Category
                </Typography>
                <FolderCopyIcon size={32} />
                <Typography variant="h3" sx={{ fontSize: "20px" }}>
                  Number of Blogs category:{" "}
                  {/* {props.client.viewContactUsLength?.totalLength || 0} */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4.5}>
            <Card
              sx={{
                backgroundColor: "#D48B89",
                color: "#fff",
                height: "200px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  height: "200px",
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "25px", fontWeight: "800" }}
                >
                  Blogs
                </Typography>
                <BorderColorIcon size={32} />
                <Typography variant="h3" sx={{ fontSize: "20px" }}>
                  Number of Blogs :{" "}
                  {/* {props.client.viewContactUsLength?.totalLength || 0} */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4.5}>
            <Card
              sx={{
                backgroundColor: "#BA7C97",
                color: "#fff",
                height: "200px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  height: "200px",
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "25px", fontWeight: "800" }}
                >
                  Contacts
                </Typography>
                <ContactsIcon size={32} />
                <Typography variant="h3" sx={{ fontSize: "20px" }}>
                  Number of Contacts :{" "}
                  {/* {props.client.viewContactUsLength?.totalLength || 0} */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Charts Section */}
        <Grid container spacing={3} sx={{ marginTop: "20px", bgcolor: "#000" }}>
          {/* <Grid item xs={12} sm={12} md={6}>
            <Card sx={{ backgroundColor: "#1abc9c", color: "#fff" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "800", marginBottom: "10px" }}
                >
                  
                </Typography>
                <Bar data={bookTestDriveData} />
              </CardContent>
            </Card>
          </Grid> */}

          <Grid item xs={12} sm={12} md={6}>
            <Card sx={{ backgroundColor: "#1abc9c", color: "#fff" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "800", marginBottom: "10px" }}
                >
                  Jobs
                </Typography>
                <Bar data={searchJobs} options={options1} />
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={6}>
            <Card sx={{ backgroundColor: "#2AACCB", color: "#fff" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "800", marginBottom: "10px" }}
                >
                  Internship
                </Typography>
                <Bar data={searchInternships} options={options2} />
              </CardContent>
            </Card>
          </Grid>  */}

          <Grid item xs={12} sm={12} md={6}>
            <Card sx={{ backgroundColor: "#D7BCF7", color: "#fff" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "800", marginBottom: "10px" }}
                >
                  Blogs
                </Typography>
                <Bar data={blogData} options={options3} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Card sx={{ backgroundColor: "#c0392b", color: "#fff" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "800", marginBottom: "10px" }}
                >
                  Contact
                </Typography>
                <Bar data={contactData} options={options} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
