import React,{useState, useEffect, useRef} from 'react';
import { Grid, Typography, Box,List,ListItem,Card,CardContent,Divider,useMediaQuery,IconButton } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { ArrowBackIos, ArrowForwardIos,  } from "@mui/icons-material";
import Service from '../../images/service (1).jpg'
import contact from '../../images/contact.jpg'
import website from '../../images/website.jpg';
import GoogleImg from '../../images/google.png'
import SEOImg from '../../images/seo.webp';
import FirewallImg from '../../images/firewall.png';
import SSLImg from '../../images/ssl.png';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const AnimatedSection = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.2,    // Triggers animation when 20% of the component is visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, delay }}
    >
      {children}
    </motion.div>
  );
};


const ServicePage = () => { 
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('website-section');
      const rect = section.getBoundingClientRect();
      if (rect.top < window.innerHeight * 0.8) {
        setTimeout(() => {
          setShowImage(true);
        }, 2000); // 2-second delay for image
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [showContent, setShowContent] = useState(false);
  const sectionRef = useRef(null);  // Use ref to track section

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.8) {
          setTimeout(() => {
            setShowContent(true);
          }, 1000);  // 1-second delay
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();  // Trigger once on mount in case already in view
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [isOpen, setIsOpen] = useState(false); 
     const ref = useRef(null);
   
     const toggleIcons = () => {
       setIsOpen((prev) => !prev);
     };
   
     const handleClickOutside = (event) => {
       if (ref.current && !ref.current.contains(event.target)) {
         setIsOpen(false);
       }
     };
   
     useEffect(() => {
       if (isOpen) {
         document.addEventListener("mousedown", handleClickOutside);
       } else {
         document.removeEventListener("mousedown", handleClickOutside);
       }
   
       return () => {
         document.removeEventListener("mousedown", handleClickOutside);
       };
     }, [isOpen]);
     

  return (
    <Box sx={{ padding: '2rem',backgroundColor:'#0F1214',color:'#fff',overflowX:'hidden', marginTop:"40px" }}>
      <Grid container spacing={4}>
      <Grid container spacing={4} alignItems="center" ref={sectionRef}>
      {/* Image Section */}
      <Grid item xs={12} md={6}>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Box
            component="img"
            src={Service}
            alt="Web Development Services"
            sx={{
              width: '100%',
              borderRadius: '8px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              marginTop: '50px'
            }}
          />
        </motion.div>
      </Grid>

      {/* Text Content Section */}
      <Grid item xs={12} md={6}>
        {showContent && (
          <motion.div
            initial={{ opacity: 0, x: 30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '1rem', marginTop: '50px' }}>
              Looking for Website Development and Design Services in India?
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
              Are you a business owner? Or any kind of services or goods you are selling and want to take it online?
              Well, you have come to the right place then. We are a website development and design service provider in India.
              In this digital era, a website is the most important thing to grow your business online as we all know.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
              But the issue is when we go online and try to find a good website development agency, that’s when the main issue occurs.
              I mean, there are thousands of website design and development agencies that are fraudulent in this business.
            </Typography>
          </motion.div>
        )}
      </Grid>
    </Grid>

        {/* Second Section with another Image */}
        <AnimatedSection>

        <Grid item xs={12}>
          <Box
            component="img"
            src={contact} 
            alt="Contact for Website Services"
            sx={{
              width: '100%',
              borderRadius: '8px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              marginTop: '2rem'
            }}
          />
        </Grid>
        </AnimatedSection>

        <Box sx={{ padding: '2rem', }}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">

      <Grid container spacing={4} alignItems="center" justifyContent="center" id="website-section">
      {/* Text Content */}
      <Grid item xs={12} md={6.7}>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            What is a Website?
          </Typography>
          <Typography variant="body1" paragraph>
            Website is a place or shop of your business on the internet, it will have a web address
            like www.xyz.com using which users can go to your business or shop over the internet.
            That's the meaning of a website in simple words. But if you prefer English, here it is 😁 
            “A website is a collection of related web pages that are stored on a server and can be 
            accessed over the internet. Each web page typically contains text, images, videos, or other 
            multimedia content and is designed to be viewed in a web browser. Websites are created for 
            various purposes, such as providing information, selling products or services, sharing ideas, 
            or connecting people.”
          </Typography>
        </motion.div>
      </Grid>

      {/* Image Content */}
      <Grid item xs={12} md={3.6}>
        {showImage && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <Box
              component="img"
              src={website}
              alt="Website Info Graphic"
              sx={{
                width: '100%',
                maxHeight: '300px',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
          </motion.div>
        )}
      </Grid>
    </Grid>

        {/* Additional Sections */}
        <AnimatedSection>

        <Grid item xs={12} md={10.7} justifyContent="center">
          <Box sx={{ padding: 4, marginLeft:{xs:2,sm:5,md:6} }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Why Your Business Needs a Website?
            </Typography>
            <Typography variant="body1">
              Think about starting a business like opening a shop. If it’s a physical store, you’d have 
              to spend a lot on rent, utilities, and other expenses. It’s pricey and limits you to one 
              location. Now, imagine a website as a smart, budget-friendly choice. It’s like a virtual 
              shop that people can visit from anywhere. No need for a specific place, and you save a ton 
              on costs. Your business isn’t stuck in one spot; it can reach people globally. Having a 
              website is like a clever investment – it’s affordable, boosts visibility, and opens doors 
              to customers all around the online world.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={10.7}>
          <Box sx={{ padding: 3 ,marginLeft:{xs:2,sm:5,md:6} }}>
            <Typography variant="h4" component="h2" gutterBottom>
              How to Find the Right Website Development and Design Services in Bhubaneswar, Odisha?
            </Typography>
            <Typography variant="body1">
              In the website game, scams are everywhere. Our crew at J Nine Infotech.com keeps it real, 
              offering legit services at wallet-friendly rates. We don’t just talk the talk; we walk 
              it too. Check out our live demos on YouTube where we show our users how we created our 
              last website, for every website we show to users that, that’s how we created this client 
              website and it was his needs. It’s like a backstage pass to see exactly what you’re 
              getting. No smoke and mirrors, just the real deal. Because we believe in being upfront 
              and showing off our work, so you know you’re getting top-notch stuff without breaking 
              the bank. Trust us, we’ve got your back in the web world.
            </Typography>
          </Box>
        </Grid>
        </AnimatedSection>

        {/* Ads card */}
        <AnimatedSection>

        <Card sx={{ padding: 4, borderRadius: '16px', boxShadow: 3, textAlign: 'center', maxWidth: 800, marginBottom:'20px' }}>
      <Typography variant="h5" gutterBottom>
        What We Offer Along With Website
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        
        {/* Google Ads */}
        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="img" src={GoogleImg} alt="Google Ads" sx={{ width: 50, height: 50 }} />
            <Typography variant="body1">₹ Free (Google Ads)</Typography>
          </Box>
        </Grid>
        
        {/* SEO Tools */}
        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="img" src={SEOImg} alt="SEO Tools" sx={{ width: 50, height: 50 }} />
            <Typography variant="body1">₹ Free (Premium SEO Tools)</Typography>
          </Box>
        </Grid>
        
        {/* Firewall */}
        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="img" src={FirewallImg} alt="Firewall Security" sx={{ width: 50, height: 50 }} />
            <Typography variant="body1">₹ Free (Firewall for Security)</Typography>
          </Box>
        </Grid>
        
        {/* SSL Certificate */}
        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="img" src={SSLImg} alt="SSL Certificate" sx={{ width: 50, height: 50 }} />
            <Typography variant="body1">₹ Free (SSL Certificate)</Typography>
          </Box>
        </Grid>
        
      </Grid>
    </Card>
    </AnimatedSection>
      </Grid>
    </Box>
        
      </Grid>
      {/* Floating Action Button with Social Icons */}
      <div
              ref={ref}
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginBottom: "10px",
                  transition: "all 0.3s ease-in-out",
                  opacity: isOpen ? 1 : 0,
                  transform: isOpen ? "scale(1)" : "scale(0.9)",
                  pointerEvents: isOpen ? "all" : "none",
                }}
              >
                {/* WhatsApp */}
                <Tooltip title="WhatsApp" arrow>
                  <Fab
                    size="small"
                    style={{ backgroundColor: "#25D366", color: "#fff" }}
                    onClick={() => window.open("https://wa.me/919583966999", "_blank")}
                  >
                    <WhatsAppIcon />
                  </Fab>
                </Tooltip>

                {/* Call */}
                <Tooltip title="Call" arrow>
                  <Fab
                    size="small"
                    color="secondary"
                    style={{ backgroundColor: "#FF5722", color: "#fff" }}
                    onClick={() => window.open("tel:+919583966999", "_self")}
                  >
                    <CallIcon />
                  </Fab>
                </Tooltip>

                {/* Instagram */}
                <Tooltip title="Instagram" arrow>
                  <Fab
                    size="small"
                    color="secondary"
                    style={{ backgroundColor: "#E1306C", color: "#fff" }}
                    onClick={() => window.open("https://www.instagram.com/j9infotech/", "_blank")}
                  >
                    <InstagramIcon />
                  </Fab>
                </Tooltip>

                {/* Facebook */}
                <Tooltip title="Facebook" arrow>
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => window.open("https://www.facebook.com/JNineInfotechh/", "_blank")}
                  >
                    <FacebookIcon />
                  </Fab>
                </Tooltip>

                {/* LinkedIn */}
                <Tooltip title="LinkedIn" arrow>
                  <Fab
                    size="small"
                    style={{ backgroundColor: "#0077B5", color: "#fff" }}
                    onClick={() => window.open("https://www.linkedin.com/company/j-nine-infotech", "_blank")}
                  >
                    <LinkedInIcon />
                  </Fab>
                </Tooltip>
              </div>

                {/* Main "+" Button */}
                <Fab
                  color="primary"
                  onClick={toggleIcons}
                  style={{
                    backgroundColor: "#3f51b5",
                    color: "#fff",
                    transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                >
                  <AddIcon />
                </Fab>
              </div>
    </Box>
  );
};

export default ServicePage;
