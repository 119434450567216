import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { styled, keyframes } from "@mui/system";
import image1 from "../../images/car logo/kia central.png";
import image2 from "../../images/car logo/BYD.png";
import image3 from "../../images/car logo/RENAULT.png";
import image4 from "../../images/car logo/HONDA CENTRAL.png";
import image5 from "../../images/car logo/TATA.png";
import image6 from "../../images/car logo/WEBSITE PROFILE[1].png";

const icons = [
  { id: 1, src: image1, alt: "Kia Central" },
  { id: 2, src: image2, alt: "BYD" },
  { id: 3, src: image3, alt: "Renault" },
  { id: 4, src: image4, alt: "Honda Central" },
  { id: 5, src: image5, alt: "Tata" },
  { id: 6, src: image6, alt: "Website Profile" },
];

// Animation for sliding in from the left with vertical offsets
const slideFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
`;

const IconWrapper = styled(Box)(({ isVisible, delay, offset }) => ({
  animation: isVisible
    ? `${slideFromLeft} 0.6s ease-in-out ${delay}s`
    : "none",
  opacity: isVisible ? 1 : 0,
  transform: isVisible ? `translateY(${offset}px)` : "none",
  transition: "opacity 0.6s ease-in-out, transform 0.6s ease-in-out",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export default function ZigzagIcons() {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <Box sx={{ px: { sm: 4, xs: 1 }, py: { sm: 6, xs: 1 }, backgroundColor: "#0F1214" }}>
      <Typography
        variant="h4"
        align="center"
        sx={{
          mb: 4,
          fontWeight: "bold",
          color: "#fff",
          fontFamily: "Montserrat, Sans-serif",
        }}
      >
        Our Clients
      </Typography>

      <Grid
        container
        justifyContent="center"
        spacing={3}
        ref={ref}
        sx={{
          maxWidth: { md: "700px", sm: "500px" },
          mx: "auto",
        }}
      >
        {icons.map((icon, index) => {
          // Offset values based on the index (up for odd, down for even)
          const offset = index % 2 === 0 ? 20 : -20;
          return (
            <Grid
              item
              xs={4.8}
              sm={4}
              md={3}
              key={icon.id}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconWrapper isVisible={inView} delay={index * 0.5} offset={offset}>
                <img
                  src={icon.src}
                  alt={icon.alt}
                  style={{
                    width: "190px",
                    height: "auto",
                    filter: "drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))",
                  }}
                />
              </IconWrapper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
